import { Component, OnInit } from '@angular/core';
import { ConfirmMessageService } from './confirm-message.service';

@Component({
    selector:'confirm',
    templateUrl:'./confirm-message.component.html',
})
export class ConfirmMessage implements OnInit{

    public message:any;
    constructor(public messageService:ConfirmMessageService){

    }

   ngOnInit(){
    this.messageService.getMessage().subscribe(message=>{
        this.message = message;
    })
   }
}