import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LoaderDirective {

    public subject = new Subject<any>();

    showLoader(val: boolean, message: string) {
        this.subject.next({
            load: val,
            mgs: message
        });
    }

    getLoader(): Observable<any> {
        return this.subject.asObservable();
    }

}