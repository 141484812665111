

export class WhiteLabelAndCoBrandingModel {
  public nDistId: number = 0;
  public nIsWhiteLabelOrCoBrand: number = 0;
  public sDomain: string = "";
  public nIsSubDomain: number = 0;
  public sSubDomainType: string = "";
  public sSubDomain: string = "";
  public nHasDefaultPage: number = 0;
  public xImage: string = "";
  public xImageForMobile: string = "";
  public nRedirect: number = 0;
  public sRedirectURL: string = "";
  public nRequestForDemo: number = 0;
  public sTitle: string = "";
  public sFooter: string = "";
  public sAboutUs: string = "";
  public sCopyRightText: string = "";
  public nCreationTime: number = 0;
  public sLogoPath: string = "";
}