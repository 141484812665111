import { Component } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './home.component.html'
})
export class HomeIndexComponent {

    constructor(config: NgbCarouselConfig) {
        config.interval = 2000;
        config.wrap = true;
        config.keyboard = false;
        config.pauseOnHover = true;
    }
    

}