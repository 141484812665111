import { PipeTransform, Injectable, Pipe } from '@angular/core';

@Pipe({
    name: 'INR'
})
@Injectable()
export class CurrencyPipeComponent implements PipeTransform {
    transform(data: any) {
        let input = data
        if (typeof data !== "string") {
            return this.convertTo2DigitInr(input);
        } else {
            input = data == "" ? 0 : parseFloat(data);
            return this.convertTo2DigitInr(input);
        }
    }

    convertTo2DigitInr(input: any) {
        if (!isNaN(input)) {
            if (input === null) {
                input = 0;
            }

            let num = parseFloat(input).toFixed(2)
            //  var currencySymbol = '₹';
            //var output = Number(input).toLocaleString('en-IN');   <-- This method is not working fine in all browsers!           
            var result: any = num.toString().split('.');
            var d = Math.round(result[1] * 10 ^ 2) / 10 ^ 2;

            var lastThree = result[0].substring(result[0].length - 3);
            var otherNumbers = result[0].substring(0, result[0].length - 3);
            if (otherNumbers != '')
                lastThree = otherNumbers == '-' ? lastThree : ',' + lastThree;
            var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

            if (result.length > 1) {
                output += "." + result[1];
            }
            //currencySymbol +
            return output;
        }
    }

}
