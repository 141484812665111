import { Component } from '@angular/core';
import { DistributorBLSerivce } from './Distributor/Shared/distributor.bl.service';
import { HttpClient } from '@angular/common/http';
import { SafeHtml, DomSanitizer, SafeStyle } from '@angular/platform-browser';
//import { HTTPStatus } from './ticker-loader.interceptor'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public backImage: SafeStyle;
  public loadingScreen: boolean = true;
  constructor(public http: HttpClient,
    public domSanitizer: DomSanitizer) {
    var pathName = window.location.host;
    this.GetThemesDetails(pathName);
  }
  OnActivate(event) {
    window.scroll(0, 0);
  }

  GetThemesDetails(pathName) {
    this.http.get<any>("/Common/GetThemesDetails?pathName=" + pathName)
      .subscribe(res => {
        let img
        if (res.Status == "OK") {

          if (res.Results.sBackgroundPath !== null) {
            img = "url('"+ res.Results.sBackgroundPath +"')";
          } else {
            img = "url('/Tickerwealth/assets/images/bg_Image.jpg')"
          }
          if (res.Results.sCSS !== null) {
            var theme = JSON.parse(res.Results.sCSS);
            document.documentElement.style.setProperty("--font-color", theme.fontColor);
            document.documentElement.style.setProperty("--font-size", theme.fontSize);
            document.documentElement.style.setProperty("--background-color", theme.bgColor);
            document.documentElement.style.setProperty("--header-background-color", theme.headerbgColor);
            document.documentElement.style.setProperty("--header-font-color", theme.headerFontColor);
            document.documentElement.style.setProperty("--header-font-size", theme.headerFontSize);
            document.documentElement.style.setProperty("--grid-bg-color", theme.gridBgColor);
            document.documentElement.style.setProperty("--grid-font-color", theme.gridFontColor);
            document.documentElement.style.setProperty("--grid-alter-row-color", theme.gridAlterRowColor);
            document.documentElement.style.setProperty("--grid-font-size", theme.gridFontSize);
            document.documentElement.style.setProperty("--bttn-bg-color", theme.bttnBgColor);
            document.documentElement.style.setProperty("--background-image", img);
          } else {
            document.documentElement.style.setProperty("--font-color", '#000');
            document.documentElement.style.setProperty("--font-size", '1.5em');
            document.documentElement.style.setProperty("--background-color", '#fff');
            document.documentElement.style.setProperty("--header-background-color", '#8be1f5');
            document.documentElement.style.setProperty("--header-font-color", '#000');
            document.documentElement.style.setProperty("--header-font-size", '1.1em');
            document.documentElement.style.setProperty("--grid-bg-color", '#8be1f5');
            document.documentElement.style.setProperty("--grid-font-color", '#000');
            document.documentElement.style.setProperty("--grid-alter-row-color", '#d4f7ff');
            document.documentElement.style.setProperty("--grid-font-size", '1em');
            document.documentElement.style.setProperty("--bttn-bg-color", '#0b65a7');
            document.documentElement.style.setProperty("--background-image", img);
          }
        } else {
          img = "url('/Tickerwealth/assets/images/bg_Image.jpg')"
          document.documentElement.style.setProperty("--font-color", '#000');
          document.documentElement.style.setProperty("--font-size", '1.5em');
          document.documentElement.style.setProperty("--background-color", '#fff');
          document.documentElement.style.setProperty("--header-background-color", '#8be1f5');
          document.documentElement.style.setProperty("--header-font-color", '#000');
          document.documentElement.style.setProperty("--header-font-size", '1.1em');
          document.documentElement.style.setProperty("--grid-bg-color", '#8be1f5');
          document.documentElement.style.setProperty("--grid-font-color", '#000');
          document.documentElement.style.setProperty("--grid-alter-row-color", '#d4f7ff');
          document.documentElement.style.setProperty("--grid-font-size", '1em');
          document.documentElement.style.setProperty("--bttn-bg-color", '#0b65a7');
          document.documentElement.style.setProperty("--background-image", img);
        }
      })
  }



}
