import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CommonLoginComponent } from './CommonLogin/common-login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AppRoutingModule } from './app.routing';
import { CommonBLSerivce } from './CommonLogin/Shared/common.bl.service';
import { CommonDLSerivce } from './CommonLogin/Shared/common.dl.service';
import { CommonService } from './CommonLogin/Shared/common-service';
import { HashLocationStrategy, LocationStrategy, TitleCasePipe } from '@angular/common';
import { GridColumnSettings } from './Shared/grid-column-setting.constant';
import { CommonFunction } from './Shared/common-function.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthGuard } from './Shared/Security/authentication.service';
import { HomeIndexComponent } from './Home/home.component';

import { CarouselModule, WavesModule } from 'angular-bootstrap-md'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastModule } from 'primeng/toast';
import { ConfirmMessage } from './Shared/ConfirmMessage/confirm-message.component';
import { ConfirmMessageService } from './Shared/ConfirmMessage/confirm-message.service';
import { LoaderComponent } from './Shared/Loader/loader.component';
import { LoaderDirective } from './Shared/Loader/loader.directive';
import { NgxSpinnerModule } from 'ngx-spinner';

import { DataService } from './services/data.service';
import { HelperService } from './services/helper.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './reuse-strategy';
import { HttpRequestInterceptor } from './services/httpInterceptor';
import { CurrencyPipeComponentFixed4 } from './Shared/currency-pipe-Fixed4.component';
import { CurrencyPipeComponent } from './Shared/currency-pipe.component';
import { DatePipe } from './Shared/date.pipe';
import { RegistrationComponent } from './components/registration/registration.component';
import { MatButtonModule, MatInputModule, MatSlideToggleModule, MatStepperModule } from '@angular/material';
import { FieldValidationDirective } from './directives/field-validation.directive';

@NgModule({
  declarations: [
    AppComponent,
    CommonLoginComponent,
    HomeIndexComponent,
    ConfirmMessage,
    LoaderComponent,
    RegistrationComponent,
    FieldValidationDirective    
  ],
  imports: [
    BrowserModule, HttpClientModule, FormsModule,
    AppRoutingModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    CarouselModule,
    WavesModule,
    NgbModule,
    ToastModule,
    NgxSpinnerModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatSlideToggleModule
  ],
  providers: [CommonBLSerivce, CommonDLSerivce, CommonService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    GridColumnSettings,
    CommonFunction,
    AuthGuard,
    ConfirmMessageService,
    LoaderDirective,
    HelperService,
    DataService,
    DatePipe,
    TitleCasePipe,
    CurrencyPipeComponent,
    CurrencyPipeComponentFixed4,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
    // { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor , multi: true}
  ],
  bootstrap: [AppComponent]
})
export class MainModule { }
