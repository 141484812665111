import { Component } from '@angular/core';
import { CommonLoginModel } from './Shared/common-login.model';
import { Router } from '@angular/router'
import { CommonBLSerivce } from './Shared/common.bl.service';
import { CommonService } from './Shared/common-service';
import { WhiteLabelAndCoBrandingModel } from './Shared/whit-label-and-cobranding.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { fade } from '../Shared/animations';
//import { Message, MessageService } from 'primeng/primeng';
declare const $: any;
@Component({
    providers: [MessageService],
    templateUrl: "./common-login.component.html",
    animations: [
        fade
    ]
})
export class CommonLoginComponent {

    public name: string = "Distributor"
    public LoginId: string = "";
    public Password: string = "";
    public changeText1: boolean = false;
    public changeText2: boolean = false;
    public changeText3: boolean = false;
    public changeText4: boolean = false;
    public changeText5: boolean = false;
    public divDistLogo: any;
    public showImage: boolean = false;
    public showWhiteCoBrand: boolean = false;
    public showWhiteLabel: boolean = false;
    public EmailIdNotValid: string = "";
    public signin: FormGroup = null;
    public whiteLabelCoBranding: WhiteLabelAndCoBrandingModel = new WhiteLabelAndCoBrandingModel();
    commonLogin: CommonLoginModel = new CommonLoginModel();
    public selectOption: FormControl = new FormControl();
    public errormessage: string = "";
    public ShowForgetPassword: boolean = false;
    public ShowContactUs: boolean = false;
    public Country: string = '';
    public State: string = '';
    public City: string = '';
    public Pincode: string = ''
    public loginId: string = '';
    public LoginType: string = '';
    // public mgs: Message[] = []; 
    public DistName;
    public DistAddress;
    public MobileNumber;
    public DistEmaillId;
    public sAboutUs: SafeHtml
    isWhiteLabel = false;
    register = false;

    constructor(public router: Router,
        public commonBLSerivce: CommonBLSerivce,
        public commonService: CommonService,
        public formBuilder: FormBuilder,
        public messageService: MessageService,
        public http: HttpClient,
        public domSanitizer: DomSanitizer) {
        this.GetImage();
        this.LoginType = "Distributor";

        if (location.host.indexOf("tickerwealth.com") > -1) {
            this.isWhiteLabel = false;
        } else {
            this.isWhiteLabel = true;
        }
    }

    ViewPassword() {

        let passField: any = document.getElementById("pass-field")
        let passIcon: any = document.getElementById("pass-icon")
        if (passField.type == "password") {
            passField.type = 'text';
            passIcon.className = 'fa fa-eye';
        } else {
            passField.type = 'password';
            passIcon.className = 'fa fa-eye-slash';
        }
    }
    GetThemesDetails() {
        this.http.get<any>("/Common/GetThemesDetails")
            .subscribe(res => {
                let img
                if (res.Status == "OK") {
                    if (res.Results.sBackgroundPath !== null) {
                        img = "url('" + res.Results.sBackgroundPath + "')";
                    } else {
                        img = "url('/Tickerwealth/assets/images/bg_Image.jpg')";
                    }
                    if (res.Results.sCSS !== null) {
                        var theme = JSON.parse(res.Results.sCSS);
                        document.documentElement.style.setProperty("--font-color", theme.fontColor);
                        document.documentElement.style.setProperty("--font-size", theme.fontSize);
                        document.documentElement.style.setProperty("--background-color", theme.bgColor);
                        document.documentElement.style.setProperty("--header-background-color", theme.headerbgColor);
                        document.documentElement.style.setProperty("--header-font-color", theme.headerFontColor);
                        document.documentElement.style.setProperty("--header-font-size", theme.headerFontSize);
                        document.documentElement.style.setProperty("--grid-bg-color", theme.gridBgColor);
                        document.documentElement.style.setProperty("--grid-font-color", theme.gridFontColor);
                        document.documentElement.style.setProperty("--grid-alter-row-color", theme.gridAlterRowColor);
                        document.documentElement.style.setProperty("--grid-font-size", theme.gridFontSize);
                        document.documentElement.style.setProperty("--bttn-bg-color", theme.bttnBgColor);
                        document.documentElement.style.setProperty("--background-image", img);
                    } else {
                        document.documentElement.style.setProperty("--font-color", '#0');
                        document.documentElement.style.setProperty("--font-size", '1.5em');
                        document.documentElement.style.setProperty("--background-color", '#fff');
                        document.documentElement.style.setProperty("--header-background-color", '#8be1f5');
                        document.documentElement.style.setProperty("--header-font-color", '#000');
                        document.documentElement.style.setProperty("--header-font-size", '1.1em');
                        document.documentElement.style.setProperty("--grid-bg-color", '#8be1f5');
                        document.documentElement.style.setProperty("--grid-font-color", '#000');
                        document.documentElement.style.setProperty("--grid-alter-row-color", '#d4f7ff');
                        document.documentElement.style.setProperty("--grid-font-size", '1em');
                        document.documentElement.style.setProperty("--bttn-bg-color", '#0b65a7');
                        document.documentElement.style.setProperty("--background-image", img);
                    }
                } else {
                    img = "url('/Tickerwealth/assets/images/bg_Image.jpg')"
                    document.documentElement.style.setProperty("--font-color", '#000');
                    document.documentElement.style.setProperty("--font-size", '1.5em');
                    document.documentElement.style.setProperty("--background-color", '#fff');
                    document.documentElement.style.setProperty("--header-background-color", '#8be1f5');
                    document.documentElement.style.setProperty("--header-font-color", '#000');
                    document.documentElement.style.setProperty("--header-font-size", '1.1em');
                    document.documentElement.style.setProperty("--grid-bg-color", '#8be1f5');
                    document.documentElement.style.setProperty("--grid-font-color", '#000');
                    document.documentElement.style.setProperty("--grid-alter-row-color", '#d4f7ff');
                    document.documentElement.style.setProperty("--grid-font-size", '1em');
                    document.documentElement.style.setProperty("--bttn-bg-color", '#0b65a7');
                    document.documentElement.style.setProperty("--background-image", img);
                }
            })
    }


    activeBtn(val) {
        localStorage.clear();
        switch (val) {
            case "Distributor": this.LoginType = "Distributor";
                localStorage.setItem("controllerName", 'Distributor');
                break;
            case "Branch": this.LoginType = "Branch";
                // window.location.href = "http://uat.tickerwealth.com/BranchLogin/login";
                localStorage.setItem("controllerName", 'BranchLogin');
                break;
            case "RM": this.LoginType = "RM";
                // window.location.href = "http://uat.tickerwealth.com/RMLogin/Login";
                localStorage.setItem("controllerName", 'RMLogin');
                break;
            case "Client": this.LoginType = "Client";
                localStorage.setItem("controllerName", 'Client');
                break;
            case "Agent": this.LoginType = "Agent";
                // window.location.href = "http://uat.tickerwealth.com/AgentLogin/Login";
                localStorage.setItem("controllerName", 'AgentLogin');
                break;

        }
        var header = document.getElementById("myDIV");
        var btns = header.getElementsByClassName("dist-type-bttn");
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("active");
                current[0].className = current[0].className.replace(" active", "");
                this.className += " active";
            });

        }
    }
    options = [
        { name: 'Distributor', val: 'Dist' },
        { name: 'Branch', val: 'Branch' },
        { name: 'RM', val: 'RM' },
        { name: 'Client', val: 'Client' },
        { name: 'Agent', val: 'Agent' },
    ]

    ngOnInit() {
        this.signin = this.formBuilder.group({
            email: ['', Validators.email],
            password: ['', Validators.required],
            name: ['', Validators.required]
        })
        this.signin.controls["name"].setValue("Distributor")
    }

    get Check() {
        return this.signin.controls;
    }

    CheckEmail(val) {
        var data = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
        if (data.test(val)) {
            this.errormessage = "";
        } else {
            this.errormessage = "Email Id is not proper.";
        }
    }

    onNavigate(val: string) {
        switch (val) {
            case "Distributor": this.signin.value.name = "Distributor";
                break;
            case "Branch": this.signin.value.name = "Branch";
                break;
            case "RM": this.signin.value.name = "RM";
                break;
            case "Client": this.signin.value.name = "Client";
                break;
            case "Agent": this.signin.value.name = "Agent";
                break;

        }
    }


    Login() {

        // var windowFeatures = "left=100,top=100,width=1000,popup=1";

        // window.open("/#/Distributor/BSE/Transaction/BSEPurchase", "_blank", windowFeatures);
        // return;

        if (this.signin.value.email.trim() == "") {
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Enter LoginId.' });
            return;
        }

        if (this.signin.value.password.trim() == "") {
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Enter Password.' });
            return;
        }


        switch (this.LoginType) {
            case "Distributor": {
                var loginData = new Object;
                loginData['sDistEmail'] = this.signin.value.email
                loginData['sDistPassword'] = this.signin.value.password
                this.commonBLSerivce.CheckLogin(this.LoginType, loginData)
                    .subscribe(res => {
                        if (res.Status == "OK") {
                            localStorage.setItem("controllerName", 'Distributor');
                            localStorage.setItem("nDistType", res.Results.nDistType);
                            localStorage.setItem("nDistId", res.Results.nDistId);
                            localStorage.setItem("userName", res.Results.sDistName);
                            sessionStorage.setItem("userName", res.Results.sDistName);
                            this.GetThemesDetails();
                            this.router.navigate(['/Distributor']);
                        } else if (res.Status == "Failed") {
                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Invalid LoginId and Password.' });
                        }
                    });
            }
                break;
            case "Branch": {
                var loginData = new Object;
                loginData['sLoginId'] = this.signin.value.email
                loginData['sPassword'] = this.signin.value.password
                this.commonBLSerivce.CheckLogin(this.LoginType, loginData)
                    .subscribe(res => {
                        if (res.Status == "OK") {
                            localStorage.setItem("controllerName", 'BranchLogin');
                            localStorage.setItem("nDistId", res.Results.nDistId)
                            localStorage.setItem("userName", res.Results.sBranchName);
                            localStorage.setItem("nBranchId", res.Results.nBranchId);
                            localStorage.setItem("nDistType", res.Results.nDistType);
                            this.GetThemesDetails();
                            this.router.navigate(['/BranchLogin']);
                            // window.location.href = "https://www.tickerwealth.com/BranchLogin/login";
                        } else if (res.Status == "Failed") {
                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Invalid LoginId and Password.' });

                        }
                    });
            }
                break;
            case "RM": {
                var loginData = new Object;
                loginData['sLoginId'] = this.signin.value.email
                loginData['sPassword'] = this.signin.value.password
                this.commonBLSerivce.CheckLogin(this.LoginType, loginData)
                    .subscribe(res => {
                        if (res.Status == "OK") {
                            localStorage.setItem("controllerName", 'RMLogin');
                            localStorage.setItem("userName", res.Results.sRMName);
                            localStorage.setItem("sRMId", res.Results.sRMId);
                            localStorage.setItem("nDistType", res.Results.nDistType);
                            this.GetThemesDetails();
                            this.router.navigate(['/RMLogin']);
                            //window.location.href = "https://www.tickerwealth.com/RMLogin/Login";
                        } else if (res.Status == "Failed") {
                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Invalid LoginId and Password.' });

                        }
                    });
            }
                break;
            case "Client": {

                var loginData = new Object;
                loginData['sLoginId'] = this.signin.value.email
                loginData['sPassword'] = this.signin.value.password
                this.commonBLSerivce.CheckLogin(this.LoginType, loginData)
                    .subscribe(res => {
                        if (res.Status == "OK") {
                            localStorage.setItem("controllerName", 'Client');
                            localStorage.setItem("userName", res.Results.sClientName);
                            localStorage.setItem("clientId", res.Results.nClientId);
                            this.commonBLSerivce.GetClientPrivileges('Client', res.Results.nClientId, 1).subscribe((response: any) => {
                                let res = JSON.parse(response);
                                let res1 = res.Table[0]
                                localStorage.setItem("clientPrivilage", JSON.stringify(res1));
                            })
                            this.GetThemesDetails();
                            this.router.navigate(['/Client']);
                        } else if (res.Status == "Failed") {
                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Invalid LoginId and Password.' });

                        }
                    });
            }
                break;
            case "Agent": {
                var loginData = new Object;
                loginData['sLoginId'] = this.signin.value.email
                loginData['sPassword'] = this.signin.value.password
                this.commonBLSerivce.CheckLogin(this.LoginType, loginData)
                    .subscribe(res => {
                        if (res.Status == "OK") {
                            localStorage.setItem("controllerName", 'AgentLogin');
                            localStorage.setItem("userName", res.Results.sAgentName);
                            localStorage.setItem("nDistType", res.Results.nDistType);
                            localStorage.setItem("sAgentId", res.Results.sAgentId);
                            localStorage.setItem("sRMID", res.Results.sRMID);
                            this.GetThemesDetails();
                            this.router.navigate(['/AgentLogin']);
                            //window.location.href = "https://www.tickerwealth.com/AgentLogin/Login";
                        } else if (res.Status == "Failed") {
                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Invalid LoginId and Password.' });

                        }
                    });
            }
                break;

        }
    }
    GetImage() {

        if (window.location.hostname.includes('rare.sunness.in')) {
            if (location.protocol !== 'https:') {
                window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
            }
        }
        if (window.location.hostname.includes('www.tickerwealth.com')) {
            if (location.protocol !== 'https:') {
                window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
            }
        }
        if (!window.location.hostname.includes("tickerwealth")) {
            this.commonBLSerivce.GetImage("")
                .subscribe(res => {
                    if (res.Status == "OK") {
                        this.whiteLabelCoBranding = res.Results;

                        localStorage.setItem("coBrandingData", JSON.stringify(this.whiteLabelCoBranding));
                        if (this.whiteLabelCoBranding.nIsWhiteLabelOrCoBrand == 1) {
                            this.showImage = true;
                        } else {
                            this.sAboutUs = this.domSanitizer.bypassSecurityTrustHtml(this.whiteLabelCoBranding.sAboutUs);
                            this.showWhiteLabel = true;
                        }

                    } else {
                        this.showImage = true;
                    }
                });
        } else {
            this.showImage = true;
        }


    }
    ContactUsModel() {
        this.ShowContactUs = true;
        this.commonBLSerivce.GetContactDetails(this.whiteLabelCoBranding.nDistId)
            .subscribe(res => {
                if (res.Status == "OK") {
                    this.DistName = res.Results[0].sDistName;
                    this.MobileNumber = res.Results[0].sDistPhone1;
                    this.DistAddress = res.Results[0].sDistAddress;
                    this.DistEmaillId = res.Results[0].sDistEmail;
                    this.Country = res.Results[0].sDistCountry;
                    this.City = res.Results[0].sDistCity;
                    this.State = res.Results[0].sDistState;
                    this.Pincode = res.Results[0].sDistPinCode;
                }


            });
    }
    ForgetPasswordModel() {
        this.ShowForgetPassword = true;
        this.loginId = '';
    }

    ForgetPassword() {
        // this.onNavigate(this.signin.value.name);

        this.commonBLSerivce.ForgetPassword((localStorage.getItem("controllerName") == null || localStorage.getItem("controllerName") == '') ? "Distributor" : localStorage.getItem("controllerName"), this.loginId)
            .subscribe(res => {
                alert(res)

            });

    }
    Close() {
        this.ShowForgetPassword = false;
        this.ShowContactUs = false;
    }
}
