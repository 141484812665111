

export class CommonLoginModel{
    public  nDistId : number = 0;
    public  nClientId: number = 0;
    public  sClientName :string ="";
    public sEmailId: string = "";
    public  nPAN: string = "";
    public  sState: string = "";
    public  sCity: string = "";
    public  sAddress: string = "";
    public  nPincode: string = "";
    public  sLoginId: string = "";
    public  sPassword : string = "";
    public  nIsActive: boolean = false;
    public  nLUT: number = 0;
    public  sLastModifiedBy: string = "";
    public  nIsMobileLogin: boolean = false;
    public  sMobileExpiryDate: string = "";
    public  nClientMergedId: number = 0;
    public  AppId: string = "";
    public  AppVer: string = "";
    public  sEUIN: string = "";
    public  sARN: string = "";
    public  sPhoneNo : string = "";
    public  sRMId : string = "";
    public  sRMName : string = "";
    public  sAgentId : string = "";
    public  sAgentName: string = "";
    public  sLastModifiedType: string = "";
    public lstCategory: Array<object> = new Array<object>();
    public lstSelectSubcategory: Array<object> = new Array<object>();
    public GetRMList : Array<object> = new Array<object>();
    public  GetAgentList : Array<object> = new Array<object>();
}