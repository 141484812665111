import { Injectable } from '@angular/core';
import { CommonDLSerivce } from './common.dl.service';
import { map } from 'rxjs/operators'

@Injectable()
export class CommonBLSerivce {
    public ControllerName: string = ""
    constructor(public commonDLSerivce: CommonDLSerivce) { }

    public CheckLogin(selectItemName: string, LoginData: object) {

        switch (selectItemName) {
            case "Distributor": this.ControllerName = "Distributor";
                break;
            case "Branch": this.ControllerName = "BranchLogin";
                break;
            case "RM": this.ControllerName = "RMLogin";
                break;
            case "Client": this.ControllerName = "Client";
                break;
            case "Agent": this.ControllerName = "AgentLogin";
                break;

        }
        // var data = JSON.stringify(LoginData)
        return this.commonDLSerivce.CheckLogin(this.ControllerName, LoginData)
            .pipe(map(res => {
                return res;
            }));
    }

    GetIndex() {
        return this.commonDLSerivce.GetIndex()
            .pipe(map(res => {
                return res;
            }));
    }

    GetDashboardDetails(distId) {
        return this.commonDLSerivce.GetDashboardDetails(distId)
            .pipe(map(res => {
                return res;
            }));
    }

    GetPrivilegeDetails(ControllerName: string) {
        return this.commonDLSerivce.GetPrivilegeDetails(ControllerName)
            .pipe(map(res => {
                return res;
            }));
    }

    GetImage(type) {
        return this.commonDLSerivce.GetImage(type)
            .pipe(map(res => {
                return res;
            }));
    }
    GetContactDetails(distid) {
        return this.commonDLSerivce.GetContactDetails(distid)
            .pipe(map(res => {
                return res;
            }));
    }

    public GetSummaryReport(DividendCheck: boolean) {
        return this.commonDLSerivce.GetSummaryReport(DividendCheck)
            .pipe(map(res => {
                return res;
            }));
    }

    public GetSIPReport() {
        return this.commonDLSerivce.GetSIPReport()
            .pipe(map(res => {
                return res;
            }));
    }

    public GetClientAllocationReport() {
        return this.commonDLSerivce.GetClientAllocationReport()
            .pipe(map(res => {
                return res;
            }));
    }
    public GetSummaryChildData(RTAType, BrokerCode, ProductCode, FolioNo) {
        return this.commonDLSerivce.GetSummaryChildData(RTAType, BrokerCode, ProductCode, FolioNo)
            .pipe(map(res => {
                return res;
            }));
    }
    public GetFamilyWiseReport(DividendCheck) {
        return this.commonDLSerivce.GetFamilyWiseReport(DividendCheck)
            .pipe(map(res => {
                return res;
            }));
    }
    public GetLevelFirstFamilyWiseData(ClientId) {
        return this.commonDLSerivce.GetLevelFirstFamilyWiseData(ClientId)
            .pipe(map(res => {
                return res;
            }));
    }

    public GetLevelSecondFamilyWiseData(RTAType, BrokerCode, ProductCode, FolioNo) {
        return this.commonDLSerivce.GetLevelSecondFamilyWiseData(RTAType, BrokerCode, ProductCode, FolioNo)
            .pipe(map(res => {
                return res;
            }));
    }

    public GeneratePDF(CookieValue, largeData, DividendCheck, DistId, SubDomainType) {
        return this.commonDLSerivce.GeneratePDF(CookieValue, largeData, DividendCheck, DistId, SubDomainType)
            .pipe(map(blob => {
                return blob;
            }));
    }

    ChangePassword(controllerName, conChangePasswordObj) {
        return this.commonDLSerivce.ChangePassword(controllerName, conChangePasswordObj)
            .pipe(map(res => {
                return res;
            }));
    }
    ForgetPassword(controllerName, Id) {
        return this.commonDLSerivce.ForgetPassword(controllerName, Id)
            .pipe(map(res => {
                return res;
            }));

    }
    GetClientPrivileges(controllerName, clientId, operationCode) {
        return this.commonDLSerivce.GetClientPrivileges(controllerName, clientId, operationCode)
            .pipe(map(res => {
                return res;
            }));
    }
}