import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
function padNumber(value: Number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    }
    else {
        return "";
    }
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}
@Injectable()
export class CommonFunction {

    ChangeDtFormatToModel(data) {
        let dt = data.split('-');
        if (dt.length == 3) {
            var date = {}
            date['day'] = parseInt(dt[0]);
            date['month'] = parseInt(dt[1]);
            date['year'] = parseInt(dt[2]);
            return date;
        } else {
            let dt = data.split('/');
            var date = {}
            date['day'] = parseInt(dt[0]);
            date['month'] = parseInt(dt[1]);
            date['year'] = parseInt(dt[2]);
            return date;
        }
    }

    // parse(value:string):NgbDateStruct
    // {
    //     if(value)
    //     {
    //         const dateParts=value.trim().split('/');

    //         if(dateParts.length==1 && isNumber(dateParts[0]))
    //         {
    //             return {year:toInteger(dateParts[0]),month:null,day:null};
    //         }
    //         else if(dateParts.length==2 && isNumber(dateParts[0]) && isNumber(dateParts[1]))
    //         {
    //             return {year:toInteger(dateParts[1]),month:toInteger(dateParts[0]),day:null};
    //         }
    //         else if(dateParts.length==3 && isNumber(dateParts[0]) && isNumber(dateParts[1])  && isNumber(dateParts[2]))
    //         {
    //             return {year:toInteger(dateParts[2]),month:toInteger(dateParts[1]),day:toInteger(dateParts[0])};
    //         }
    //     }
    //     return null;
    // }
    format(date: NgbDateStruct): string {
        let stringDate: string = "";

        if (date) {
            stringDate += isNumber(date.day) ? padNumber(date.day) + "-" : "";
            stringDate += isNumber(date.month) ? padNumber(date.month) + "-" : "";
            stringDate += date.year;
        }
        return stringDate;
    }

    ChangeModelToDtFormat(date) {
        //  let changedDate = date.day + '-' + date.month + '-' + date.year;
        return this.format(date);
    }

    ChangeFullDateToShortFormat(date) {
        return moment(date).format('DD-MM-YYYY').toString()
    }

    ChangeDtFormat(date) {
        return moment(date).format("DD-MM-YYYY");
    }

    DownloadFile(data, fileName) {
        var arrList = this.base64ToArrayBuffer1(data)
        // var sampleArr = this.base64ToArrayBuffer(arrList);
        const blob = new Blob([arrList]);
        // fileName = "fileName.csv";
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            if (link.download != undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    downloadPDFFile(Response, fileName) {
        var ArrFrombase64;
        var pdfBytes
        if (fileName == "") {
            var splt = Response.split('|');
            var fileName = splt[1];
            pdfBytes = splt[0];

        } else {
            pdfBytes = Response;

        }
        ArrFrombase64 = this.base64ToArrayBuffer(pdfBytes);
        var blob = new Blob([ArrFrombase64], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName + '.pdf';
        link.click();
    }

    openPDFFile(fileURL) {        
        var link = document.createElement('a');
        link.href = fileURL;
        link.target = "_blank";
        //link.download = fileName + '.pdf';
        link.click();
    }

    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);

        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    base64ToArrayBuffer1(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);

        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    // base64ToArrayBuffer(base64: string) {
    //     const binarystring = window.atob(base64);
    //     const bytes = new Uint8Array(binarystring.length);
    //     return bytes.map((byte, i) => binarystring.charCodeAt(i));

    // }

    saveAsExcelFile(buffer: any, fileName: string): void {
        import("file-saver").then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
        });
    }
    saveAsCSVFile(buffer: any, fileName: string): void {
        import("file-saver").then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.csv';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
        });
    }
    saveAsPDFFile(buffer: any, fileName: string): void {
        import("file-saver").then(FileSaver => {
            let EXCEL_TYPE = 'application/pdf';
            let EXCEL_EXTENSION = '.pdf';
            const data: Blob = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
        });
    }

    addDate(baseDate, freq, noOfUnits) {
        let inputDate = new Date(baseDate.year, baseDate.month - 1, baseDate.day);
        let returnDate = new Date(baseDate.year, baseDate.month - 1, baseDate.day);
        switch (freq) {
            case 'DAILY':
                returnDate.setDate(inputDate.getDay() + noOfUnits);
                break;
            case 'WEEKLY':
                returnDate.setDate(inputDate.getDay() + (noOfUnits * 7));
                break;
            case 'MONTHLY':
                returnDate.setMonth(inputDate.getMonth() + noOfUnits);
                break;
            case 'QUARTERLY':
                returnDate.setMonth(inputDate.getMonth() + (noOfUnits * 3));
                break;
        }

        let formatedDate = moment(returnDate, "DD-MM-YYYY").format("DD-MM-YYYY");
        return this.ChangeDtFormatToModel(formatedDate);
    }
}