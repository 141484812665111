import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    let date = new Date(value);
    return date.getDate() + "-" + this.getShortMonth(date.getMonth()) + "-" + date.getFullYear();
  }

  getShortMonth(month) {
    switch (month) {
      case 0:
        return 'Jan';        
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';        
      case 3:
        return 'Apr';        
      case 4:
        return 'May';        
      case 5:
        return 'Jun';        
      case 6:
        return 'Jul';        
      case 7:
        return 'Aug';        
      case 8:
        return 'Sep';        
      case 9:
        return 'Oct';        
      case 10:
        return 'Nov';        
      case 11:
        return 'Dec';        
    }
  }



}
