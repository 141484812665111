import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  post(url, input) {
    return this.http.post(url, input);
  }

  postWithOptions(url, input, options) {
    return this.http.post(url, input, options);
  }

  get(url) {
    return this.http.get(url);
  }

}
