import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { RegEx } from 'src/app/common/constants';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements OnInit {
  pan_validation = RegEx.pan;
  occupation_list: any = [
    { value: "01", label: 'BUSINESS' },
    { value: "02", label: 'SERVICES' },
    { value: "03", label: 'PROFESSIONAL' },
    { value: "04", label: 'AGRICULTURE' },
    { value: "05", label: 'RETIRED' },
    { value: "06", label: 'HOUSEWIFE' },
    { value: "07", label: 'STUDENT' },
    { value: "08", label: 'OTHERS' }
  ];
  tax_list: any = [
    { value: "01", label: 'INDIVIDUAL' },
    { value: "02", label: 'ON BEHALF OF MINOR' },
    { value: "03", label: 'HUF' },
    { value: "04", label: 'COMPANY' },
    { value: "06", label: 'PARTNERSHIP FIRM' },
    { value: "11", label: 'NRI' }
  ];
  holding_nature_list: any = [
    { value: "single", label: 'Single' },
    { value: "joint", label: 'Joint' },
    { value: "other", label: 'Anyone or Survivor' },
  ];
  exempt_category_list: any = [
    { "code": "01", "name": "SIKKIM Rescodeent" },
    { "code": "02", "name": "Transactions carried out on behalf of STATE GOVT" },
    { "code": "03", "name": "Transactions carried out on behalf of CENTRAL GOVT" },
    { "code": "04", "name": "COURT APPOINTED OFFICIALS" },
    { "code": "05", "name": "UN Entity/Multilateral agency exempt from paying tax in India" },
    { "code": "06", "name": "Official Liqucodeator" },
    { "code": "07", "name": "Court Receiver" },
    { "code": "08", "name": "Investment in Mutual Funds Upto Rs. 50,000/- p.a. including SIP" }
  ];
  account_type_list: any = [
    { value: "SB", label: 'SAVING BANK' },
    { value: "CB", label: 'CURRENT BANK' },
    { value: "NE", label: 'NRE ACCOUNT' },
    { value: "NO", label: 'NRO ACCOUNT' }
  ];
  bank_list: any = [
    { value: '', label: '' }
  ];
  state_list: any = [
    { value: '', label: '' }
  ];
  communication_type: any = [
    { value: 'P', label: 'PHYSICAL' },
    { value: 'E', label: 'EMAIL' },
    { value: 'M', label: 'MOBILE' }
  ];
  payment_mode_list: any = [
    { 'value': "01", label: 'CHEQUE' },
    { 'value': "02", label: 'DIRECT CREDIT' },
    { 'value': "04", label: 'NEFT' },
    { 'value': "05", label: 'RTGS' },
  ];
  ckyc_type_list: any = [
    {
      value: "K", label: 'KRA Compliant'
    },
    {
      value: "C", label: 'CKYC Compliant'
    },
    {
      value: "B", label: 'BIOMETRIC KYC'
    },
    { value: "E", label: 'Aadhaar Ekyc PAN' },
  ];
  filter_list: any = [
    { value: 'SE', label: 'Self' },
    { value: 'SP', label: 'Spouse' },
    { value: 'DC', label: 'Dependend Children' },
    { value: 'DS', label: 'Dependent Sibilings' },
    { value: 'DP', label: 'Depended Parents' },
    { value: 'GD', label: 'Gaurdian' },
    { value: 'PM', label: 'PMS' },
    { value: 'CD', label: 'Custodian' },
    { value: 'PO', label: 'POA' },
  ]
  firstFormGroup: any;
  secondFormGroup: any;
  thirdFormGroup: any;
  fourthFormGroup: any;
  fifthFormGroup: any;
  flagGroup: any;

  constructor(private _formBuilder: FormBuilder) {
    this.declareForms()
  }

  ngOnInit() {
    this.addNominee();
    this.addBank();
  }
  declareForms() {
    this.firstFormGroup = this._formBuilder.group({
      ARN: ['74654', Validators.required],
      client_code: ['', [Validators.required, Validators.maxLength(10)]],
      member_code: ['df4g564', Validators.required],
      tax_status: ['', Validators.required],
      holding_nature: ['', Validators.required],
      status: [false],
      occupation: [''],
      gender: [''],
      applicant1: this._formBuilder.group({
        first_name: ['', Validators.required],
        middle_name: [''],
        last_name: ['', Validators.required],
        dob: ['', Validators.required],
        PAN_exempt: [false, Validators.required],
        exempt_category: ['', Validators.required],
        exempt_ref_no: ['', Validators.required],
        PAN_no: ['', Validators.required],
        kyc_type: ['', Validators.required],
        kyc_number: ['']
      }),
      applicant2: this._formBuilder.group({
        first_name: ['', Validators.required],
        middle_name: [''],
        last_name: ['', Validators.required],
        dob: ['', Validators.required],
        PAN_exempt: [false, Validators.required],
        exempt_category: ['', Validators.required],
        exempt_ref_no: ['', Validators.required],
        PAN_no: ['', Validators.required],
        kyc_type: ['', Validators.required],
        kyc_number: ['']
      }),
      applicant3: this._formBuilder.group({
        first_name: [''],
        middle_name: [''],
        last_name: [''],
        dob: [''],
        PAN_exempt: [false, Validators.required],
        exempt_category: ['', Validators.required],
        exempt_ref_no: ['', Validators.required],
        PAN_no: ['', Validators.required],
        kyc_type: ['', Validators.required],
        kyc_number: ['']
      }),
      gaurdian: this._formBuilder.group({
        first_name: [''],
        middle_name: [''],
        last_name: [''],
        dob: [''],
        PAN_exempt: [false, Validators.required],
        exempt_category: ['', Validators.required],
        exempt_ref_no: ['', Validators.required],
        PAN_no: ['', Validators.required],
        kyc_type: ['', Validators.required],
        kyc_number: ['']
      }),
      show_applicant3: [false],
    });
    this.secondFormGroup = this._formBuilder.group({
      nominees: this._formBuilder.array([]),
    });
    this.thirdFormGroup = this._formBuilder.group({
      bankDetail: this._formBuilder.group({
        accounts: this._formBuilder.array([]),
        payment_mode: ['', Validators.required],
        cheque_name: ['', Validators.required],
      })
    });
    this.fourthFormGroup = this._formBuilder.group({
      address: this._formBuilder.group({
        address1: ['', Validators.required],
        address2: [''],
        address3: [''],
        pincode: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        country: ['', Validators.required],
        resi_phone: [''],
        resi_fax: [''],
        off_phone: [''],
        off_fax: [''],
      }),
      foreign_address: this._formBuilder.group({
        address1: ['', Validators.required],
        address2: [''],
        address3: [''],
        pincode: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        country: ['', Validators.required],
        resi_phone: [''],
        resi_fax: [''],
        off_phone: [''],
        off_fax: [''],
      }),
      communucation_mode: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      email_filter: ['', Validators.required],
      phone: ['', Validators.required],
      phone_filter: ['', Validators.required]
    });
    this.fifthFormGroup = this._formBuilder.group({
      depositoryDetails: this._formBuilder.group({
        client_type: ['', Validators.required],
        name: [''],
        pms: [''],
        cdsl_dp_id: ['', Validators.required],
        cdsl_client_id: ['', Validators.required],
        cdsl_acc_no: [''],
        nsdl_dp_id: ['', Validators.required],
        nsdl_client_id: ['', Validators.required],
        nsdl_acc_no: [''],
        cmbp_id: ['', Validators.required],
      }),
      otherDetails: this._formBuilder.group({
        is_adhaar_updated: ['', Validators.required],
        paperless_flag: [false],
        mapin_id: [''],
        lei_num: [''],
        lei_validity: ['', Validators.required],
      }),
      signature: this._formBuilder.group({
        cheque_image: [{ value: '', disabled: true }, Validators.required],
        applicant1_sign_img: [{ value: '', disabled: true }],
        applicant2_sign_img: [{ value: '', disabled: true }],
        applicant3_sign_img: [{ value: '', disabled: true }],
        applicant4_sign_img: [{ value: '', disabled: true }],
        gaurdian_sign_img: [{ value: '', disabled: true }],
      })
    })
    this.flagGroup = this._formBuilder.group({
      TaxStatusFlag: [false],
      TaxStatusFlag2: [false],
      NRIMandate: [false],
      MultipleHolder: [false]
    })
  }
  addNominee() {
    // if (this.nominees.controls.length <= 3) {
    let nomineeForm = this._formBuilder.group({
      nname: ['', Validators.required],
      relationship: ['', Validators.required],
      percentage: ['', Validators.required],
      minor: [false],
      dob: [''],
      gaurdian: ['']
    })
    this.nominees.push(nomineeForm);
  }
  removeNominee(index) {
    this.nominees.removeAt(index)
  }
  get nominees() {
    return this.secondFormGroup.get('nominees') as FormArray;
  }
  addBank() {
    if (this.accounts.controls.length <= 4) {
      this.accounts.push(
        this._formBuilder.group({
          type: ['', Validators.required],
          ac_number: ['', Validators.required],
          ifsc_code: ['', Validators.required],
          micr_no: [''],
          bank_name: ['', Validators.required],
          branch_name: ['', Validators.required],
          is_default_bank: [''],
        })
      )
    }
  }
  removeBank(index) {
    this.accounts.removeAt(index)
  }
  get accounts() {
    return this.thirdFormGroup.controls['bankDetail'].get('accounts') as FormArray;
  }
  UploadFile(file, type) {

  }
  getFormValues() {
    
    var data: any = {};
    data.firstFormGroup = this.firstFormGroup.value;
    data.secondFormGroup = this.secondFormGroup.value;
    data.thirdFormGroup = this.thirdFormGroup.value;
    data.fourthFormGroup = this.fourthFormGroup.value;
    data.fifthFormGroup = this.fifthFormGroup.value;

  }
  selectTaxStatus() {
    let val = this.firstFormGroup.get('tax_status').value;
    if (val == '01') {
      this.flagGroup.controls['TaxStatusFlag'].patchValue(true);
      this.flagGroup.controls['TaxStatusFlag2'].patchValue(false);
      this.flagGroup.controls['NRIMandate'].patchValue(false);
    } else if (val == '02') {
      this.flagGroup.controls['TaxStatusFlag'].patchValue(true);
      this.flagGroup.controls['TaxStatusFlag2'].patchValue(true);
    } else if (val == '11' || val == '26' || val == '27' ||
      val == '28' || val == '29' || val == '76' || val == '77') {
      this.flagGroup.controls['NRIMandate'].patchValue(true);
    } else {
      this.flagGroup.controls['TaxStatusFlag'].patchValue(false);
      this.flagGroup.controls['TaxStatusFlag2'].patchValue(false);
      this.flagGroup.controls['NRIMandate'].patchValue(false);
    }
    this.resetField('gender');
    this.resetField('gaurdian');
  }
  changeHolderType() {
    let val = this.firstFormGroup.get('holding_nature').value;
    let new_val = val == 'single' ? false : true;
    this.flagGroup.controls['MultipleHolder'].patchValue(new_val);
    this.resetField('applicant2');
    this.resetField('applicant3');
  }
  resetField(field, group = null, subgroup = null, subfield = null, index = null) {
    switch (field) {
      case 'gender':
        this.firstFormGroup.controls.gender.reset();
        break;
      case 'gaurdian':
        this.firstFormGroup.controls.gaurdian.reset();
        break;
      case 'applicant1':
        this.firstFormGroup.controls.applicant1.reset();
        break;
      case 'applicant2':
        this.firstFormGroup.controls.applicant2.reset();
        break;
      case 'applicant3':
        this.firstFormGroup.controls.applicant3.reset();
        break;
      case 'depository':
        this.fifthFormGroup.controls.depositoryDetails.get('name').reset();
        this.fifthFormGroup.controls.depositoryDetails.get('pms').reset();
        this.resetField('depository1');
        this.resetField('pms');
        break;
      case 'depository1':
        this.fifthFormGroup.controls.depositoryDetails.get('cdsl_dp_id').reset();
        this.fifthFormGroup.controls.depositoryDetails.get('cdsl_client_id').reset();
        this.fifthFormGroup.controls.depositoryDetails.get('cdsl_acc_no').reset();
        this.fifthFormGroup.controls.depositoryDetails.get('nsdl_dp_id').reset();
        this.fifthFormGroup.controls.depositoryDetails.get('nsdl_client_id').reset();
        this.fifthFormGroup.controls.depositoryDetails.get('nsdl_acc_no').reset();
        break;
      case 'pms':
        this.fifthFormGroup.controls.depositoryDetails.get('cmbp_id').reset();
        break;
      case 'custom':
        if (subgroup != null) {
          this[group].controls[subgroup].get(subfield).reset();
        } else {
          this[group].get(subfield).reset();
        }
        break;
      case 'dynamic':
        this[group].controls[subgroup].controls[index].get(subfield).reset();
        break;
      case 'all':
        this.firstFormGroup.reset();
        this.secondFormGroup.reset();
        this.thirdFormGroup.reset();
        this.fourthFormGroup.reset();
        this.fifthFormGroup.reset();
        break;
    }

  }
  setApplicationValue(value) {
    this.firstFormGroup.controls['show_applicant3'].patchValue(value);
  }
  getValue(formGroup, subgroup = null, field) {
    if (subgroup != null) {
      return this[formGroup].controls[subgroup].get(field).value;
    } else {
      return this[formGroup].get(field).value;
    }
  }
}