import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor(private dataService: DataService) { }

    paginatedPost(url, input, callBack) {
        this.dataService.post(url, input).subscribe(
            (response) => {
                if (response) {
                    // if (callBack(response)) {
                    //     this.paginatedPost(url, input, callBack);
                    // }
                }
            },
            (error) => {

            }
        )
    }

    paginatedGet(url, input, callBack) {
        let placeHolder = "";
        if (!url.includes("?")) {
            placeHolder = "?";
        } else {
            placeHolder = "&";
        }
        let params = `${placeHolder}pageNumber=${input.pageNumber}&pageSize=${input.pageSize}`;
        this.dataService.get(url + params).subscribe(
            (response) => {
                if (response) {
                    if (callBack(response)) {
                        input.pageNumber = input.pageNumber + 1;
                        this.paginatedGet(url, input, callBack);
                    }
                }
            },
            (error) => {

            }
        )
    }
}
