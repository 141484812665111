import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class ConfirmMessageService{

    public subject = new Subject<any>();
    showMessage(message:string,yes:()=>void,no:()=>void){
        let that = this;
        this.subject.next({
            type:'confirm',
            text:message,
            yes:()=>{
                that.subject.next();
                yes();
            },
            no:()=>{
                that.subject.next();
                no();
            }

        })
    }

    getMessage():Observable<any>{
        return this.subject.asObservable();
    }



}