import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';


@Injectable()
export class CommonDLSerivce {
    public options = {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    }
    constructor(public http: HttpClient, private dataService: DataService) { }
    public CheckLogin(ControllerName: string, LoginData) {

        return this.http.post<any>(ControllerName + '/Login', LoginData, this.options);
    }

    GetIndex() {
        return timer(0, 100000).pipe(switchMap(_ => this.http.get<any>('/Common/GetIndexValues', this.options)));
    }

    GetDashboardDetails(distId) {
        return this.http.get<any>('/AgentLogin/GetDashboardDetails?nDistId=' + distId, this.options);
    }

    GetPrivilegeDetails(ControllerName: string) {
        return this.http.get<any>(ControllerName + '/GetPrivilegeDetails', this.options);
    }

    GetImage(type) {
        return this.http.get<any>('/Common/GetDistLogo?type=' + type, this.options);
    }
    GetContactDetails(distid) {
        return this.http.get<any>('/Common/GetContactDetails?nDistId=' + distid, this.options);
    }
    public GetSIPReport() {
        return this.http.get<any>('/Client/GetClientWiseSchemeSIPDetails', this.options);
    }

    public GetClientAllocationReport() {
        return this.http.get<any>('/Client/GetClientWiseAllocationReport', this.options);
    }

    public GetSummaryReport(DividendCheck: boolean) {
        return this.http.get<any>('/Client/GetFolioWiseSummaryReport?dividendCheck=' + DividendCheck, this.options);
    }

    public GetSummaryChildData(RTAType, BrokerCode, ProductCode, FolioNo) {
        return this.http.get<any>('/Client/GetClientSchemeWiseTransactionReport?RTAType=' + RTAType + '&BrokerCode=' + BrokerCode + '&ProductCode=' + ProductCode + '&FolioNo=' + FolioNo, this.options);
    }

    public GetFamilyWiseReport(DividendCheck) {
        return this.http.get<any>('/Client/GetFamilyWiseAllocationReport?dividendCheck=' + DividendCheck, this.options);
    }

    public GetLevelFirstFamilyWiseData(ClientId) {
        return this.http.get<any>('/Client/GetFolioWiseSummaryReportForFamily?clientid=' + ClientId, this.options);
    }

    public GetLevelSecondFamilyWiseData(RTAType, BrokerCode, ProductCode, FolioNo) {
        return this.http.get<any>('/Client/GetClientSchemeWiseTransactionReport?RTAType=' + RTAType + '&BrokerCode=' + BrokerCode + '&ProductCode=' + ProductCode + '&FolioNo=' + FolioNo, this.options);
    }

    public GeneratePDF(CookieValue, largeData, DividendCheck, DistId, SubDomainType) {
        //  var headers = new HttpHeaders({ 'Content-Type': 'application/pdf' })
        return this.http.get('/Client/GenerateChartPdf?CookieValue=' + CookieValue + '&largeData=' + largeData + '&dividendCheck=' + DividendCheck + '&nDistId=' + DistId + '&sSubDomainType=' + SubDomainType, { responseType: 'blob' });
    }

    ChangePassword(controllerName, conChangePasswordObj) {
        return this.http.post<any>('/' + controllerName + '/ChangePassword', conChangePasswordObj, this.options);
    }

    ForgetPassword(controllerName, Id) {
        return this.http.get<any>('/' + controllerName + '/ForgetPassword?Id=' + Id, this.options);

    }


    //////////////////////////////////////////////////////// Bhavesh Jain /////////////////////////////////////////////////////////////

    GetSchemeList(schemelist: GetSchemeList) {
        return this.dataService.post("Masters/GetSchemeList", schemelist);
    }
    GetClientPrivileges(controllerName, clientId, operationCode) {
        return this.http.get<any>('/' + controllerName + '/GetClientPrivileges?nClientId=' + clientId + '&nOperationCode=' + operationCode);
    }
}

export class GetSchemeList {
    amcCode: string = "";
    bseAMCCode: string = "";
    schemeCode: string = "";
    bseSchemeCode: string = "";
    settlementType: string = "";
    transactionType: string = "";
    categoryType: string = "";
}