

export class CONTROLLER_NAME_CONSTANT{
    public Distributor:string = 'Distributor';
    public DealerDash:string = 'DealerDashboard';
    public Agent:string = 'AgentLogin';
    public Branch:string = 'BranchLogin';
    public RM:string = 'RMLogin';
    public Client:string = 'Client';
    public BSEStarMF:string = 'BSEStarMF';
}