
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConstantTypes } from '../constant-type';
import { CONTROLLER_NAME_CONSTANT } from '../controller-name-constant.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'

@Injectable()
export class AuthGuard implements CanActivate {

    public controllerName: string = "";
    public constantType: ConstantTypes = new ConstantTypes();
    public controName: CONTROLLER_NAME_CONSTANT = new CONTROLLER_NAME_CONSTANT();
    constructor(private router: Router, public http: HttpClient) {
      
    }
    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    canActivate(): Observable<boolean> {

        this.controllerName = localStorage.getItem("controllerName");
        // var url: any = state.url;
        let sessionDetails: any = [];
        let type = "";
        switch (this.controllerName) {
            case this.controName.Distributor: type = this.constantType.DISTRIBUTOR_CONSTANT;
                break;
            case this.controName.Branch: type = this.constantType.BRANCH_CONSTANT;
                break;
            case this.controName.RM: type = this.constantType.RM_CONSTANT;
                break;
            case this.controName.Agent: type = this.constantType.AGENT_CONSTANT;
                break;
            case this.controName.Client: type = this.constantType.CLIENT_CONSTANT;
                break;
        }

        return this.http.get<any>("Common/Get_Session_Details?type=" + type).pipe(map(res => {
            if (res.Status = "OK") {
                sessionDetails = res.Results;
            }
            if (sessionDetails.length !== 0) {
                return true;
            } else {
                let coBrandingData = JSON.parse(localStorage.getItem("coBrandingData"));
                if (coBrandingData !== null && coBrandingData != undefined) {
                    let redirectURL = coBrandingData.sRedirectURL;
                    localStorage.clear();
                    if (redirectURL != "" && redirectURL != null) {
                        window.location.href = redirectURL;
                    } else {
                        localStorage.clear();
                        localStorage.removeItem("controllerName");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("sRMId");
                        localStorage.removeItem("nDistType");
                        this.router.navigateByUrl('/')
                        // this.router.navigate(['/Login']);
                    }
                } else {
                    // localStorage.clear();
                    // this.router.navigate(['/Login']);
                        localStorage.removeItem("controllerName");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("sRMId");
                        localStorage.removeItem("nDistType");
                        this.router.navigateByUrl('/')
                }
                return false;
            }

            //return res;
        }));

        // .subscribe(res => {
        //     if (res.Status = "OK") {
        //         sessionDetails = res.Results;

        //         if (sessionDetails.length !== 0) {
        //             return true;
        //         } else {
        //             let coBrandingData = JSON.parse(localStorage.getItem("coBrandingData"));
        //             if (coBrandingData !== null && coBrandingData != undefined) {
        //                 let redirectURL = coBrandingData.sRedirectURL;
        //                 localStorage.clear();
        //                 if (redirectURL != "" && redirectURL != null) {
        //                     window.location.href = redirectURL;
        //                 } else {
        //                     this.router.navigate(['/Login']);
        //                 }
        //             } else {
        //                 this.router.navigate(['/Login']);
        //             }
        //             return false;
        //         }
        //     }
        // })

    }
    // localStorage.getItem("userName")



}

