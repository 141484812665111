import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoaderDirective } from './loader.directive';

@Component({
    selector: "loader",
    templateUrl: "./loader.component.html"
})

export class LoaderComponent implements OnInit {
    // public controllerName:string = "";
    @Input('loaderText')
    public loaderText: string = "Downloading..."
    public showLoader:boolean = false;
    constructor(private spinner: NgxSpinnerService,
        public loaderDirective:LoaderDirective) {
        this.spinner.show();
    }

    ngOnInit() {
        this.loaderDirective.getLoader().subscribe(val =>{
            this.showLoader = val.load;
            this.loaderText = val.mgs;
        })
    }


}