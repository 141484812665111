import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

export class CustomReuseStrategy implements RouteReuseStrategy {
    routesToCache = ["Dashboard"];
    storedRouteHandles = new Map<string, DetachedRouteHandle>();
    private handlers: { [key: string]: DetachedRouteHandle } = {}

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if (route.routeConfig.path.indexOf("Login") > -1) {
            this.storedRouteHandles.clear();
        }
        return this.routesToCache.indexOf(route.routeConfig.path) > -1;
        //throw new Error("Method not implemented.");
    }
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRouteHandles.set(route.routeConfig.path, handle);
        //throw new Error("Method not implemented.");
    }
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return this.storedRouteHandles.has(route.routeConfig.path);
        //throw new Error("Method not implemented.");
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRouteHandles.get(route.routeConfig.path);
        // return route.pathFromRoot
        // .map(v=>v.url.map(segment=>segment.toString()).join('/'))
        // .filter(url=>!!url)
        // .join('/')

        //throw new Error("Method not implemented.");

        // if (!route.routeConfig) {
        //     return null;
        // };
        // if (route.routeConfig.loadChildren || route.routeConfig.children) {
        //     return null
        // }
        // return this.handlers[this.getUrl(route)];
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
        //throw new Error("Method not implemented.");
    }
    getUrl(route: ActivatedRouteSnapshot): string {
        if (route.routeConfig) {
            const url = route.routeConfig.path;
            return url;
        }
        // return route.pathFromRoot
        // .map(v=>v.url.map(segment=>segment.toString()).join('/'))
        // .filter(url=>!!url)
        // .join('/')
    }
}