import { CommonLoginComponent } from './CommonLogin/common-login.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './Shared/Security/authentication.service';
import { RegistrationComponent } from './components/registration/registration.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: "", redirectTo: "Login", pathMatch: "full" },
            // { path: "", component: HomeIndexComponent },
            // { path: "Home/Index", component: HomeIndexComponent },
            { path: "", component: CommonLoginComponent },
            { path: "Login", component: CommonLoginComponent },
            { path: "Distributor", loadChildren: "./Distributor/distributor-main.module#DistributorMainModule", canActivate: [AuthGuard] },
            { path: "AgentLogin", loadChildren: "./Distributor/distributor-main.module#DistributorMainModule", canActivate: [AuthGuard] },
            { path: "BranchLogin", loadChildren: "./Distributor/distributor-main.module#DistributorMainModule", canActivate: [AuthGuard] },
            { path: "Client", loadChildren: "./Distributor/distributor-main.module#DistributorMainModule", canActivate: [AuthGuard] },
            { path: "RMLogin", loadChildren: "./Distributor/distributor-main.module#DistributorMainModule", canActivate: [AuthGuard] },
            { path: 'Registration', component: RegistrationComponent }
        ])
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {

}