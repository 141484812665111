
export class GridColumnSettings {
    public AMCWiseAUMColumn = [
        // { field: '', header: '', width: '3em',TotalColumn:'sAMCName' },
        { field: 'SR_NO_', header: 'Sr No.', width: '7em', TotalColumn: 'sAMCName' },
        { field: 'sAMCName', header: 'AMC Name', width: '25em', tooltip: true, headerFilter: "sAMCName", TotalColumn: 'sAMCName', NotTitleCase: true },
        { field: 'nInvestment', header: 'Investment value', width: '14em', format: true, headerFilter: "nInvestment", align: true, TotalColumn: 'sAMCName', customFilter: "nInvestment" },
        { field: 'nAUM', header: 'AUM', width: '14em', format: true, headerFilter: "nAUM", align: true, TotalColumn: 'sAMCName', customFilter: "nAUM" },
        { field: 'nPANCount', header: 'Unique Client', width: '13em', align: true, TotalColumn: 'sAMCName' },
        { field: 'nLiveFolio', header: 'Live Folio', width: '13em', align: true, TotalColumn: 'sAMCName' },
        { field: 'Contribution', header: 'Contribution %', width: '13em', format: true, align: true, TotalColumn: 'sAMCName' }
    ];
    public SchemeWiseAUMColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em', TotalColumn: 'sSchemeName' },
        { field: 'sSchemeName', header: 'Scheme Name', width: '25em', tooltip: true, headerFilter: "sSchemeName", TotalColumn: 'sSchemeName', NotTitleCase: true, sort: 'sSchemeName' },
        { field: 'nInvestment', header: 'Investment Value', width: '12em', cellFilter: 'number: 2', format: true, headerFilter: "nInvestment", align: true, TotalColumn: 'sSchemeName', sort: 'nInvestment' },
        { field: 'nAUM', header: 'AUM', width: '12em', cellFilter: 'number: 2', format: true, headerFilter: "nAUM", align: true, TotalColumn: 'sSchemeName', sort: 'nAUM' },
        { field: 'nPANCount', header: 'Unique Client', width: '13em', align: true, TotalColumn: 'sSchemeName', sort: 'nPANCount' },
        { field: 'nLiveFolio', header: 'Live Folio', width: '13em', align: true, TotalColumn: 'sSchemeName', sort: 'nLiveFolio' },
        { field: 'Contribution', header: 'Contribution %', width: '13em', format: true, align: true, TotalColumn: 'sSchemeName', sort: 'Contribution' }
    ];

    public ClientWiseAUMColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '6em' },
        { field: 'sInv_Name', header: 'Client Name', width: '15em', tooltip: true, headerFilter: "sInv_Name" },
        { field: 'sFoliochk', header: 'Folio No', width: '10em', align: true },
        { field: 'nInvestment', header: 'Investment Value', width: '11em', format: true, headerFilter: "nInvestment", align: true },
        { field: 'nAUM', header: 'AUM', width: '11em', format: true, headerFilter: "nAUM", align: true },
        { field: 'nDivPayout', header: 'Dividend Paid', width: '15em', format: true, align: true },
        { field: 'nDivReinvest', header: 'Dividend Reinvestment', width: '17em', format: true, align: true },
        { field: 'Abs_Return', header: 'Abs. Return', width: '13em', format: true, align: true }
    ];



    public ClientWiseConAUMColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em', TotalColumn: 'sClientName' },
        { field: 'sClientName', header: 'Client Name', width: '28em', tooltip: true, headerFilter: "sClientName", TotalColumn: 'sClientName', sort: 'sClientName' },
        { field: 'nInvestment', header: 'Investment Value', width: '17em', format: true, headerFilter: "nInvestment", align: true, TotalColumn: 'sClientName', sort: 'nInvestment' },
        { field: 'nAUM', header: 'AUM', width: '17em', format: true, headerFilter: "nAUM", align: true, TotalColumn: 'sClientName', sort: 'nAUM' },
        { field: 'nFolioCount', header: 'Folio`s', width: '13em', align: true, TotalColumn: 'sClientName', sort: 'nFolioCount' },
        { field: 'nDivPayout', header: 'Div Payout', width: '13em', format: true, align: true, TotalColumn: 'sClientName', sort: 'nDivPayout' },
        { field: 'nDivReinvest', header: 'Div Reinvest', width: '13em', format: true, align: true, TotalColumn: 'sClientName', sort: 'nDivReinvest' },
    ]

    public ClientWiseSchemeAUMColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em' },
        { field: 'sScheme_Name', header: 'Scheme Name', width: '28em', tooltip: true, headerFilter: "sScheme_Name", NotTitleCase: true, sort: 'sScheme_Name' },
        { field: 'nInvestment', header: 'Investment', width: '17em', format: true, headerFilter: "nInvestment", align: true, NotTitleCase: true, sort: 'nInvestment' },
        { field: 'nAUM', header: 'AUM', width: '17em', format: true, headerFilter: "nAUM", align: true, NotTitleCase: true, sort: 'nAUM' },
        { field: 'sFoliochk', header: 'Folio No.', width: '13em', align: true, sort: 'sFoliochk' },
        { field: 'nDivPayout', header: 'Dividend Payout', width: '13em', format: true, align: true, sort: 'nDivPayout' },
        { field: 'nDivReinvest', header: 'Dividend Reinvest', width: '13em', format: true, align: true, sort: 'nDivReinvest' },

    ]

    public FamilyWiseAUMColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em' },
        { field: 'sClientName', header: 'Client Name', width: '28em', tooltip: true, headerFilter: "sClientName", sort: 'sClientName' },
        { field: 'nAUM', header: 'AUM', width: '17em', format: true, headerFilter: "nAUM", align: true, sort: 'nAUM' },
        { field: 'nMembersCount', header: 'Members', width: '13em', align: true, sort: 'nMembersCount' },
        { field: 'nDivPayout', header: 'Div Payout', width: '13em', format: true, align: true, sort: 'nDivPayout' },
        { field: 'nDivReinvest', header: 'Div Reinvest', width: '13em', format: true, align: true, sort: 'nDivReinvest' },

    ]
    public FamilyWiseClientAUMColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em' },
        { field: 'sClientName', header: 'Client Name', width: '28em', tooltip: true, headerFilter: "sClientName", sort: 'sClientName' },
        { field: 'nAUM', header: 'AUM', width: '17em', format: true, headerFilter: "nAUM", align: true, sort: 'nAUM' },
        { field: 'nFolioCount', header: 'Folio`s', width: '13em', align: true, sort: 'nFolioCount' },
        { field: 'nDivPayout', header: 'Div Payout', width: '13em', format: true, align: true, sort: 'nDivPayout' },
        { field: 'nDivReinvest', header: 'Div Reinvest', width: '13em', format: true, align: true, sort: 'nDivReinvest' },

    ]

    public CategoryWiseAUMColumns = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em' },
        { field: 'sParentCategory', header: 'Category', width: '28em', tooltip: true, headerFilter: "sParentCategory", sort: 'sParentCategory' },
        { field: 'nAUM', header: 'AUM', width: '17em', format: true, headerFilter: "nAUM", align: true, sort: 'nAUM' },
        { field: 'nPANCount', header: 'Unique Client', width: '13em', align: true, sort: 'nPANCount' },
        { field: 'nLiveFolio', header: 'Live Folio', width: '13em', align: true, sort: 'nLiveFolio' },
        { field: 'Contribution', header: 'Contribution %', width: '15em', format: true, align: true, sort: 'Contribution' }
    ]
    public SubCategoryWiseAUMColumns = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em' },
        { field: 'sSchemeCategoryName', header: 'Scheme Category Name', width: '28em', tooltip: true, headerFilter: "sSchemeCategoryName", NotTitleCase: true, sort: 'sSchemeCategoryName' },
        { field: 'nAUM', header: 'AUM', width: '13em', cellFilter: 'number: 2', align: true, format: true, headerFilter: "nAUM", sort: 'nAUM' },
        { field: 'nPANCount', header: 'Unique Client', width: '13em', align: true, sort: 'nPANCount' },
        { field: 'nLiveFolio', header: 'Live Folio', width: '13em', align: true, sort: 'nLiveFolio' },
        { field: 'Contribution', header: 'Contribution %', width: '15em', format: true, align: true, sort: 'Contribution' }
    ]
    public TransactionReportColumn = [
        { field: 'sRMName', header: 'RM Name', width: '13em', tooltip: true, headerFilter: "sRMName" },
        { field: 'sAgentName', header: 'Partner Name', width: '13em', tooltip: true, headerFilter: "sAgentName" },
        { field: 'sInv_Name', header: 'Client Name', width: '14em', tooltip: true, headerFilter: "sInv_Name" },
        { field: 'sScheme', header: 'Scheme Name', width: '25em', tooltip: true, headerFilter: "sScheme", NotTitleCase: true },
        { field: 'sFolio_No', header: 'Folio No', width: '13em', headerFilter: "sFolio_No" },
        { field: 'nTradDate', header: 'Transaction Date', width: '13em' },
        { field: 'sTrxn_Type', header: 'Transaction Type', width: '15em' },
        { field: 'nAmount', header: 'Amount', width: '13em', format: true, align: true, headerFilter: "nAmount", customFilter: 'exact' },
        { field: 'nUnits', header: 'Units', width: '13em', format: true, align: true }

    ]


    public TransactionReportColumnRM = [
        { field: 'sRMName', header: 'RM Name', width: '13em', tooltip: true, headerFilter: "sRMName" },
        { field: 'NAME', header: 'Partner Name', width: '13em', tooltip: true, headerFilter: "sAgentName" },
        { field: 'sInv_Name', header: 'Client Name', width: '14em', tooltip: true, headerFilter: "sInv_Name" },
        { field: 'sScheme', header: 'Scheme Name', width: '25em', tooltip: true, headerFilter: "sScheme", NotTitleCase: true },
        { field: 'sFolio_No', header: 'Folio No', width: '13em', headerFilter: "sFolio_No" },
        { field: 'nTradDate', header: 'Transaction Date', width: '13em' },
        { field: 'sTrxn_Type', header: 'Transaction Type', width: '15em' },
        { field: 'nAmount', header: 'Amount', width: '13em', format: true, align: true, headerFilter: "nAmount", customFilter: 'exact' },
        { field: 'nUnits', header: 'Units', width: '13em', format: true, align: true }


    ]

    public TransactionReportColumnAgentBranch = [
        { field: 'sInv_Name', header: 'Client Name', width: '14em', tooltip: true, headerFilter: "sInv_Name" },
        { field: 'sScheme', header: 'Scheme Name', width: '25em', tooltip: true, headerFilter: "sScheme" },
        { field: 'sFolio_No', header: 'Folio No', width: '13em', headerFilter: "sFolio_No" },
        { field: 'nTradDate', header: 'Transaction Date', width: '13em' },
        { field: 'sTrxn_Type', header: 'Transaction Type', width: '15em' },
        { field: 'nAmount', header: 'Amount', width: '13em', format: true, align: true, headerFilter: "nAmount", customFilter: 'exact' },
        { field: 'nUnits', header: 'Units', width: '13em', format: true, align: true }
    ]

    public TransactionReportColumnIFA = [
        { field: 'sInv_Name', header: 'Client Name', width: '14em', tooltip: true, headerFilter: "sInv_Name" },
        { field: 'sScheme', header: 'Scheme Name', width: '25em', tooltip: true, headerFilter: "sScheme", NotTitleCase: true },
        { field: 'sFolio_No', header: 'Folio No', width: '13em', headerFilter: "sFolio_No" },
        { field: 'nTradDate', header: 'Transaction Date', width: '13em' },
        { field: 'sTrxn_Type', header: 'Transaction Type', width: '15em' },
        { field: 'nAmount', header: 'Amount', width: '13em', format: true, align: true, headerFilter: "nAmount", customFilter: 'exact' },
        { field: 'nUnits', header: 'Units', width: '13em', format: true, align: true }

    ]

    public SWPReportColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '8em', TotalColumn: 'sScheme_Name' },
        { field: 'sNAME', header: 'Client Name', width: '14em', tooltip: true, headerFilter: "sNAME", TotalColumn: 'sScheme_Name', sort: 'sNAME' },
        { field: 'sScheme_Name', header: 'Scheme Name', width: '28em', tooltip: true, headerFilter: "sScheme_Name", TotalColumn: 'sScheme_Name', NotTitleCase: true, sort: 'sScheme_Name' },
        { field: 'sFolio_No', header: 'Folio No', width: '15em', align: true, TotalColumn: 'sScheme_Name', sort: 'sFolio_No' },
        { field: 'SWPAmount', header: 'Amount', width: '13em', format: true, align: true, TotalColumn: 'sScheme_Name', sort: 'SWPAmount' },
        { field: 'Reg_Date', header: 'Reg Date', width: '13em', TotalColumn: 'sScheme_Name', customFilter: 'exact', sort: 'Reg_Date' },
        { field: 'Start_Date', header: 'Start Date', width: '13em', TotalColumn: 'sScheme_Name', sort: 'Start_Date' },
        { field: 'End_Date', header: 'End Date', width: '13em', TotalColumn: 'sScheme_Name', sort: 'End_Date' }
    ]
    public SWPReportColumnDist = [
        { field: 'SR_NO_', header: 'Sr No.', width: '8em', TotalColumn: 'sScheme_Name' },
        { field: 'sNAME', header: 'Client Name', width: '14em', tooltip: true, headerFilter: "sNAME", TotalColumn: 'sScheme_Name', sort: 'sNAME' },
        { field: 'sScheme_Name', header: 'Scheme Name', width: '28em', tooltip: true, headerFilter: "sScheme_Name", TotalColumn: 'sScheme_Name', NotTitleCase: true, sort: 'sScheme_Name' },
        { field: 'sFolio_No', header: 'Folio No', width: '15em', align: true, TotalColumn: 'sScheme_Name', sort: 'sFolio_No' },
        { field: 'SWPAmount', header: 'Amount', width: '13em', format: true, align: true, TotalColumn: 'sScheme_Name', sort: 'SWPAmount' },
        { field: 'Reg_Date', header: 'Reg Date', width: '13em', TotalColumn: 'sScheme_Name', customFilter: 'exact', sort: 'Reg_Date' },
        { field: 'Start_Date', header: 'Start Date', width: '13em', TotalColumn: 'sScheme_Name', sort: 'Start_Date' },
        { field: 'End_Date', header: 'End Date', width: '13em', TotalColumn: 'sScheme_Name', sort: 'End_Date' },
        { field: 'Delete', header: 'Delete', width: '6em', btnClass: 'fa fa-trash bttn-delete', showButtonLabel: true, tooltip: true, Type: 'Button', FieldName: 'Delete' }
    ]

    public STPReportColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '8em', TotalColumn: 'sScheme_Name' },
        { field: 'sInv_Name', header: 'Client Name', width: '15em', tooltip: true, headerFilter: "sInv_Name", TotalColumn: 'sScheme_Name', sort: 'sInv_Name' },
        { field: 'sFolio_No', header: 'Folio No.', width: '10em', align: true, TotalColumn: 'sScheme_Name', sort: 'sFolio_No' },
        { field: 'sScheme_Name', header: 'Source Scheme', width: '15em', tooltip: true, headerFilter: "sScheme_Name", TotalColumn: 'sScheme_Name', NotTitleCase: true, sort: 'sScheme_Name' },
        { field: 'sTarget_SchemeName', header: 'Target Scheme', width: '14em', tooltip: true, TotalColumn: 'sScheme_Name', sort: 'sTarget_SchemeName' },
        { field: 'STPAmount', header: 'Amount', width: '13em', format: true, align: true, TotalColumn: 'sScheme_Name', customFilter: 'exact', sort: 'STPAmount' },
        { field: 'Reg_Date', header: 'Reg Date', width: '13em', TotalColumn: 'sScheme_Name', sort: 'Reg_Date' },
        { field: 'Start_Date', header: 'Start Date', width: '13em', TotalColumn: 'sScheme_Name', sort: 'Start_Date' },
        { field: 'End_Date', header: 'End Date', width: '13em', TotalColumn: 'sScheme_Name', sort: 'End_Date' }
    ];
    public STPReportColumnDist = [
        { field: 'SR_NO_', header: 'Sr No.', width: '8em', TotalColumn: 'sScheme_Name' },
        { field: 'sInv_Name', header: 'Client Name', width: '15em', tooltip: true, headerFilter: "sInv_Name", TotalColumn: 'sScheme_Name', sort: 'sInv_Name' },
        { field: 'sFolio_No', header: 'Folio No.', width: '10em', align: true, TotalColumn: 'sScheme_Name', sort: 'sFolio_No' },
        { field: 'sScheme_Name', header: 'Source Scheme', width: '15em', tooltip: true, headerFilter: "sScheme_Name", TotalColumn: 'sScheme_Name', NotTitleCase: true, sort: 'sScheme_Name' },
        { field: 'sTarget_SchemeName', header: 'Target Scheme', width: '14em', tooltip: true, TotalColumn: 'sScheme_Name', sort: 'sTarget_SchemeName' },
        { field: 'STPAmount', header: 'Amount', width: '13em', format: true, align: true, TotalColumn: 'sScheme_Name', customFilter: 'exact', sort: 'STPAmount' },
        { field: 'Reg_Date', header: 'Reg Date', width: '13em', TotalColumn: 'sScheme_Name', sort: 'Reg_Date' },
        { field: 'Start_Date', header: 'Start Date', width: '13em', TotalColumn: 'sScheme_Name', sort: 'Start_Date' },
        { field: 'End_Date', header: 'End Date', width: '13em', TotalColumn: 'sScheme_Name', sort: 'End_Date' },
        { field: 'Delete', header: 'Delete', width: '6em', btnClass: 'fa fa-trash bttn-delete', showButtonLabel: true, tooltip: true, Type: 'Button', FieldName: 'Delete' }
    ];

    public SIPExpiringReportColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '5em', TotalColumn: 'Scheme_Name' },
        { field: 'Client_Name', header: 'Client Name', width: '19em', tooltip: true, headerFilter: "Client_Name", TotalColumn: 'Scheme_Name' },
        { field: 'Scheme_Name', header: 'Scheme Name', width: '19em', tooltip: true, headerFilter: "Scheme_Name", TotalColumn: 'Scheme_Name', NotTitleCase: true },
        { field: 'Folio_No', header: 'Folio No', width: '15em', align: true, TotalColumn: 'Scheme_Name' },
        { field: 'SIP_Amt', header: 'Amount', width: '13em', format: true, align: true, headerFilter: "SIP_Amt", TotalColumn: 'Scheme_Name', customFilter: 'exact' },
        { field: 'Reg_Date', header: 'Reg Date', width: '13em', TotalColumn: 'Scheme_Name' },
        { field: 'Start_Date', header: 'Start Date', width: '13em', TotalColumn: 'Scheme_Name' },
        { field: 'End_Date', header: 'End Date', width: '13em', TotalColumn: 'Scheme_Name' }
    ]

    public SIPCeasedReportColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '5em', TotalColumn: 'Scheme_Name' },
        { field: 'Client_Name', header: 'Client Name', width: '15em', tooltip: true, headerFilter: "Client_Name", TotalColumn: 'Scheme_Name' },
        { field: 'Scheme_Name', header: 'Scheme Name', width: '19em', tooltip: true, headerFilter: "Scheme_Name", TotalColumn: 'Scheme_Name', NotTitleCase: true },
        { field: 'Folio_No', header: 'Folio No', width: '10em', align: true, headerFilter: "Folio_No", TotalColumn: 'Scheme_Name' },
        { field: 'SIP_Amt', header: 'Amount', width: '10em', format: true, align: true, headerFilter: "SIP_Amt", TotalColumn: 'Scheme_Name', customFilter: 'exact' },
        { field: 'Reg_Date', header: 'Reg Date', width: '10em', TotalColumn: 'Scheme_Name', date: true },
        { field: 'Start_Date', header: 'Start Date', width: '10em', TotalColumn: 'Scheme_Name', date: true },
        { field: 'End_Date', header: 'End Date', width: '10em', TotalColumn: 'Scheme_Name', date: true },
        { field: 'Cease_Date', header: 'Ceased Date', width: '12em', TotalColumn: 'Scheme_Name', date: true },
        { field: 'Remarks', header: 'Remarks', width: '35em', TotalColumn: 'Scheme_Name' }
    ]

    public SIPBouncedReportColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em', TotalColumn: 'Scheme_Name' },
        { field: 'Client_Name', header: 'Client Name', width: '11em', tooltip: true, headerFilter: "Client_Name", TotalColumn: 'Scheme_Name' },
        { field: 'Scheme_Name', header: 'Scheme Name', width: '20em', tooltip: true, headerFilter: "Scheme_Name", TotalColumn: 'Scheme_Name', NotTitleCase: true },
        { field: 'Folio_No', header: 'Folio No', width: '11em', align: true },
        { field: 'SIP_Amt', header: 'Amount', width: '11em', format: true, align: true, TotalColumn: 'Scheme_Name', customFilter: 'exact' },
        { field: 'Transaction_Nature', header: 'Transaction Nature', width: '17em', TotalColumn: 'Scheme_Name' },
        { field: 'Trade_Date', header: 'Trade Date', width: '11em', TotalColumn: 'Scheme_Name' }

    ]

    public SIPAdditionReportColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em', TotalColumn: 'Scheme_Name' },
        { field: 'Client_Name', header: 'Client Name', width: '14em', tooltip: true, headerFilter: "Client_Name", TotalColumn: 'Scheme_Name', sort: 'Client_Name' },
        { field: 'Scheme_Name', header: 'Scheme Name', width: '28em', tooltip: true, headerFilter: "Scheme_Name", TotalColumn: 'Scheme_Name', NotTitleCase: true, sort: 'Scheme_Name' },
        { field: 'Folio_No', header: 'Folio No', width: '15em', align: true, TotalColumn: 'Scheme_Name', sort: 'Folio_No' },
        { field: 'SIP_Amt', header: 'Amount', width: '13em', format: true, align: true, headerFilter: "SIP_Amt", TotalColumn: 'Scheme_Name', customFilter: 'exact', sort: 'SIP_Amt' },
        { field: 'Reg_Date', header: 'Reg Date', width: '11em', TotalColumn: 'Scheme_Name', sort: 'Reg_Date' },
        { field: 'Start_Date', header: 'Start Date', width: '12em', TotalColumn: 'Scheme_Name', sort: 'Start_Date' },
        { field: 'End_Date', header: 'End Date', width: '11em', TotalColumn: 'Scheme_Name', sort: 'End_Date' }
    ]

    public SIPExpiryReportColumns = [
        { field: 'nTradDate', header: 'Trad Date', width: '12em', tooltip: true, TotalColumn: 'Scheme_Name' },
        { field: 'sTrxn_Type', header: 'Trxn_Type', width: '21em', tooltip: true, headerFilter: "sTrxn_Type", TotalColumn: 'Scheme_Name' },
        { field: 'nAmount', header: 'Amount', width: '12em', format: true, align: true, headerFilter: "nAmount", TotalColumn: 'Scheme_Name' },
        { field: 'nUnits', header: 'Units', width: '12em', format: true, align: true, TotalColumn: 'Scheme_Name' },
        { field: 'Trxn_NAV', header: 'Tran. NAV', width: '12em', align: true, TotalColumn: 'Scheme_Name' },
        { field: 'Cum_Units', header: 'Cum.Units', width: '12em', align: true, TotalColumn: 'Scheme_Name' },
        { field: 'nDivReinv', header: 'Div Reinv', width: '12em', format: true, align: true, TotalColumn: 'Scheme_Name' },
        { field: 'nDivPur', header: 'Div Pur', width: '12em', format: true, align: true, TotalColumn: 'Scheme_Name' },
        { field: 'nDays', header: 'Days', width: '5em', align: true, TotalColumn: 'Scheme_Name' },
        { field: 'nCurrValue', header: 'Curr Value', width: '13em', format: true, align: true, TotalColumn: 'Scheme_Name' }
    ]

    public AMCWiseSIPColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em', TotalColumn: 'sAMCName' },
        { field: 'sAMCName', header: 'AMC Name', width: '24em', tooltip: true, headerFilter: "sAMCName", NotTitleCase: true, TotalColumn: 'sAMCName' },
        { field: 'NoOfSIP', header: 'No of SIP', width: '13em', align: true, headerFilter: "NoOfSIP", TotalColumn: 'sAMCName', NotTitleCase: true },
        { field: 'SIPAmount', header: 'SIP Amount', width: '13em', format: true, align: true, headerFilter: "SIPAmount", customFilter: "exact", TotalColumn: 'sAMCName' },
        { field: 'Average_SIP', header: 'Average SIP', width: '13em', format: true, align: true, TotalColumn: 'sAMCName' },
        { field: 'Contribution', header: 'Contribution %', width: '13em', format: true, align: true, TotalColumn: 'sAMCName' }
    ];
    public SchemeWiseSIPColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em', TotalColumn: 'sScheme_Name' },
        { field: 'sScheme_Name', header: 'Scheme Name', width: '24em', tooltip: true, headerFilter: "sScheme_Name", TotalColumn: 'sScheme_Name', NotTitleCase: true },
        { field: 'NoOfSIP', header: 'No of SIP', width: '13em', align: true, headerFilter: "NoOfSIP", TotalColumn: 'sScheme_Name' },
        { field: 'SIPAmount', header: 'SIP Amount', width: '15em', format: true, align: true, headerFilter: "SIPAmount", customFilter: "exact", TotalColumn: 'sScheme_Name' },
        { field: 'Contribution', header: 'Contribution %', width: '15em', format: true, align: true, TotalColumn: 'sScheme_Name' }
    ];
    public ClientWiseSIPColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '5em', TotalColumn: 'sInv_Name' },
        { field: 'sInv_Name', header: 'Client Name', width: '11em', tooltip: true, headerFilter: "sInv_Name", TotalColumn: 'sInv_Name' },
        { field: 'sFolio_No', header: 'Folio No', width: '9em', align: true, TotalColumn: 'sInv_Name' },
        { field: 'SIP_Frequency', header: 'Frequency', width: '8em', align: true, TotalColumn: 'sInv_Name' },
        { field: 'SIPAmount', header: 'Installment', width: '8em', format: true, align: true, headerFilter: "SIPAmount", customFilter: "exact", TotalColumn: 'sInv_Name' },
        { field: 'Reg_Date', header: 'Reg_Date', width: '10em', TotalColumn: 'sInv_Name' },
        { field: 'Start_Date', header: 'Start Date', width: '10em', TotalColumn: 'sInv_Name' },
        { field: 'End_Date', header: 'End Date', width: '10em', TotalColumn: 'sInv_Name' },
        { field: 'Deduction_Date', header: 'Deduction Date', width: '5em', TotalColumn: 'sInv_Name' }
    ];
    public ClientWiseConSIPColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '7em' },
        { field: 'sInv_Name', header: 'Client Name', width: '24em', tooltip: true, headerFilter: "sInv_Name" },
        { field: 'SIP_Amount', header: 'SIP Amount', width: '13em', format: true, align: true, headerFilter: "SIP_Amount", customFilter: "exact" },
        { field: 'No_Of_SIP', header: 'No Of SIP', width: '15em', align: true }

    ];
    public ClientWiseConSchemeSIPColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '10em' },
        { field: 'sScheme_Name', header: 'Scheme Name', width: '28em', tooltip: true, headerFilter: "sScheme_Name", NotTitleCase: true },
        { field: 'sFolio_No', header: 'Folio No', width: '13em', align: true },
        { field: 'SIP_Frequency', header: 'Frequency', width: '13em', tooltip: true, align: true },
        { field: 'SIPAmount', header: 'Installment', width: '13em', format: true, align: true, headerFilter: "SIPAmount", customFilter: "exact" },
        { field: 'Reg_Date', header: 'Reg Date', width: '10em' },
        { field: 'Start_Date', header: 'Start Date', width: '10em' },
        { field: 'End_Date', header: 'End Date', width: '10em' },
        { field: 'Deduction_Date', header: 'Deduction Date', width: '12em' }

    ];
    public AgentWisePerformanceColumn = [
        { field: 'sAgentName', header: 'Partner Name', width: '14em', tooltip: true, headerFilter: "sAgentName" },
        { field: 'sRMName', header: 'RM Name', width: '14em', headerFilter: "sRMName" },
        { field: 'sBranchName', header: 'Branch Name', width: '14em', headerFilter: "sBranchName" },
        { field: 'AUM', header: 'AUM', width: '14em', format: true, align: true, headerFilter: "AUM" },
        { field: 'SIP', header: 'SIP Amount', width: '13em', format: true, align: true },
        { field: 'TotalClient', header: 'Clients', width: '13em', align: true }
    ]

    public RMWiseClientColumn = [
        { field: 'sRMName', header: 'RM Name', width: '14em', tooltip: true, headerFilter: "sRMName" },
        { field: 'sBranchName', header: 'Branch Name', width: '14em', headerFilter: "sBranchName" },
        { field: 'AUM', header: 'AUM', width: '14em', headerFilter: "AUM", format: true, align: true },
        { field: 'SIP', header: 'SIP Amount', width: '14em', format: true, align: true },
        { field: 'TotalAgent', header: 'Agents', width: '13em', align: true },
        { field: 'TotalClient', header: 'Clients', width: '13em', align: true }
    ]

    public RMWiseAgentColumn = [
        { field: 'sAgentName', header: 'Partner Name', width: '28em', tooltip: true, headerFilter: "sAgentName" },
        { field: 'AUM', header: 'AUM Amount', width: '25em', format: true, headerFilter: "AUM", align: true },
        { field: 'SIP', header: 'SIP Amount', width: '25em', format: true, align: true },
        { field: 'TotalClient', header: 'Total Client', width: '14em', align: true }
    ]

    public BranchWiseClientColumn = [
        { field: 'sBranchName', header: 'Branch Name', width: '15em', tooltip: true, headerFilter: "sBranchName" },
        { field: 'AUM', header: 'AUM', width: '10em', format: true, headerFilter: "AUM", align: true },
        { field: 'SIP', header: 'SIP Amount', width: '10em', format: true, align: true },
        { field: 'TotalRM', header: 'RM', width: '13em', align: true },
        { field: 'TotalAgent', header: 'Agents', width: '13em', align: true },
        { field: 'TotalClient', header: 'Clients', width: '13em', align: true }

    ]

    public BranchWiseRMColumn = [
        { field: 'sRMName', header: 'RM Name', width: '19em', tooltip: true, headerFilter: "sRMName" },
        { field: 'AUM', header: 'AUM', width: '19em', format: true, align: true, headerFilter: "AUM" },
        { field: 'SIP', header: 'SIP Amount', width: '14em', format: true, align: true },
        { field: 'TotalAgent', header: 'Agents', width: '14em', align: true },
        { field: 'TotalClient', header: 'Clients', width: '14em', align: true }
    ]

    public BranchWiseAgentColumn = [
        { field: 'sAgentName', header: 'Partner Name', width: '28em', tooltip: true, headerFilter: "sAgentName" },
        { field: 'AUM', header: 'AUM', width: '25em', format: true, headerFilter: "AUM" },
        { field: 'SIP', header: 'SIP Amount', width: '25em', format: true },
        { field: 'TotalClient', header: 'Clients', width: '14em' }
    ]

    public ClientWiseCapitalGainColumn = [
        { field: 'ISIN', header: 'ISIN Number', width: '10em', tooltip: true, headerFilter: "sIsin" },
        { field: 'sScheme', header: 'Scheme', width: '21em', tooltip: true, headerFilter: "sScheme" },
        { field: 'Scheme_type', header: 'Scheme Type', tooltip: true, headerFilter: "Scheme_type" },
        { field: 'sFolio_No', header: 'Folio No', align: true },
        { field: 'nActualCP', header: 'Actual Investment', format: true, align: true },
        { field: 'Investment', header: 'After Grandfathering', format: true, align: true },
        { field: 'Redemption', header: 'Redemption', align: true },
        { field: 'ST_Gain', header: 'ST Gain', format: true, align: true },
        { field: 'LT_Gain', header: 'LT Gain', format: true, align: true },
        { field: 'Indexation', header: 'Indexation', align: true },
        { field: 'Total', header: 'Total Without Indexation', format: true, align: true },
        { field: 'TotalIndexation', header: 'Total With Indexation', align: true }
    ]
    public ClientWiseNationalGainColumn = [
        // { field: 'Scheme', header: 'Scheme', tooltip: true, headerFilter: "Scheme" },
        { field: 'SchemeName', header: 'Scheme', width: '25em', tooltip: true, headerFilter: "SchemeName" },
        { field: 'Folio_No', header: 'Folio No', width: '10em', tooltip: true, headerFilter: "Folio_No" },
        // { field: 'ProdCode', header: 'ProdCode', tooltip: true, headerFilter: "ProdCode" },
        { field: 'BrokCode', header: 'ARN', tooltip: true, headerFilter: "BrokCode" },
        // { field: 'AMCCode', header: 'AMC Code', tooltip: true, headerFilter: 'AMCCode', align: true },

        { field: 'STAmount', header: 'ST Amount', format: true, align: true },
        { field: 'STUnits', header: 'ST Units', align: true },
        { field: 'LTAmount', header: 'LT Amount', format: true, align: true },
        { field: 'LTUnits', header: 'LT Units', format: true, align: true },
        { field: 'NotionalAmount', header: 'Notional Amount', align: true },
        { field: 'NotionalUnits', header: 'Notional Units', format: true, align: true },
    ]

    public ClientWiseCapitalGainTransColumn = [
        { field: 'dtTradDate', header: 'Purchase Date', width: '13em' },
        { field: 'Taxn_Type', header: 'Taxn Type', width: '11em', headerFilter: "Taxn_Type" },
        { field: 'nUnits', header: 'Pur.Units', width: '11em', format: true, align: true },
        { field: 'nPurPrice', header: 'Pur.Nav', width: '11em', format: true, align: true },
        { field: 'CP', header: 'Pur.Amount', width: '12em', format: true, align: true },
        { field: 'nRevisePurPrice', header: 'NAV as on 31-Jan-18', width: '18em', align: true },
        { field: 'nReviseCP', header: 'Market Value as on 31-Jan-18', width: '20em', align: true },
        { field: 'dtRedemDate', header: 'Red.Date', width: '11em', align: true },
        { field: 'Taxn_Type1', header: 'Trxn Type', width: '11em', align: true },
        { field: 'Redem_Units', header: 'Red.Units', width: '11em', format: true, align: true },
        { field: 'nSellPrice', header: 'Red.Nav', width: '11em', format: true, align: true },
        { field: 'SP', header: 'Red.Amount', width: '12em', format: true, align: true },
        { field: 'nNoofDays', header: 'Days', width: '9em', align: true },
        { field: 'Profit_Loss', header: 'Gain/Loss', width: '12em', format: true, align: true },
        { field: 'L_S_Category', header: 'Short/Long Term', width: '12em', align: true },

    ]
    public ClientWiseNationalGainTransColumn = [
        { field: 'sTD_TrType', header: 'Txn Type', width: '13em' },
        { field: 'dtTradDate', header: 'Date', width: '11em', headerFilter: "dtTradDate", date: true },
        { field: 'nInvestment', header: 'Amount', headerFilter: "nInvestment" },
        { field: 'nUnits', header: 'Units', headerFilter: "nUnits" },
        { field: 'nPurPrice', header: 'Price', headerFilter: "nPurPrice", format: true },
        { field: 'Redemption', header: 'Redemption', headerFilter: "Redemption" },
        { field: 'Date', header: 'Date', width: '11em', headerFilter: "Date", date: true },
        { field: 'Amount', header: 'Amount', headerFilter: "Amount" },
        { field: 'nUnits1', header: 'Units', headerFilter: "nUnits1" },
        { field: 'Price', header: 'Price', headerFilter: "Price", format: true },
        { field: 'Days', header: 'Days', headerFilter: "Days", align: true },
        { field: 'Gain/Loss', header: 'Gain/Loss', width: '12em', format: true, align: true },
    ]

    public FolioWiseSummaryReportColumn = [
        // { field: 'SR_NO_', header: 'Sr No.', width: '10em' },
        { field: 'sScheme', header: 'Scheme Name', width: '30em', tooltip: true, headerFilter: "sScheme", TotalColumn: 'sScheme' },
        { field: 'sFolio_No', header: 'Folio No', width: '11em', align: true, headerFilter: "sFolio_No", TotalColumn: 'sScheme' },
        { field: 'Category', header: 'Category', width: '15em', tooltip: true, headerFilter: "Category", TotalColumn: 'sScheme' },
        { field: 'dtStartDate', header: 'Start Date', width: '8em', TotalColumn: 'sScheme', dateColumn: true },
        { field: 'nInvestment', header: 'Investment', width: '8em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'nUnits', header: 'Units', width: '8em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'nPurPrice', header: 'Avg NAV', width: '8em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'Divi_Paid', header: 'Dividend Paid', width: '8em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'Divi_ReInv', header: 'Dividend Reinvestment', width: '8em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'Current_NAV', header: 'Cur NAV', width: '8em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'CurVal', header: 'Cur Val', width: '8em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'OneDayPerChange', header: '1 Day Change', width: '8em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'GainLoss', header: 'Gain/Loss', width: '11em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'AVGTradingDate', header: 'Avg Days', width: '11em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'AbsReturn', header: 'Abs Ret', width: '11em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'CAGR', header: 'XIRR', width: '11em', format: true, align: true, TotalColumn: 'sScheme' }
    ]
    public ZeroFolioWiseSummaryReportColumn = [
        { field: 'SR_NO_', header: 'Sr No.', width: '10em' },
        { field: 'SchemeName', header: 'Scheme Name', width: '30em', tooltip: true, headerFilter: "SchemeName", TotalColumn: 'SchemeName' },
        { field: 'sFolio_No', header: 'Folio No', width: '11em', align: true, headerFilter: "sFolio_No", TotalColumn: 'SchemeName' },
        { field: 'Category', header: 'Category', width: '15em', tooltip: true, headerFilter: "Category", TotalColumn: 'SchemeName' },
    ]

    public ClientSchemeWiseTransactionReportColumn = [
        { field: 'nTradDate', header: 'Trad Date', width: '10em', dateColumn: true },
        { field: 'sTrxn_Type', header: 'Trxn_Type', width: '16em', headerFilter: "sTrxn_Type" },
        { field: 'nAmount', header: 'Amount', width: '9em', format: true, align: true },
        { field: 'nUnits', header: 'Units', width: '9em', format: true, align: true },
        { field: 'Trxn_NAV', header: 'Tran. NAV', width: '10em', format: true, align: true },
        { field: 'Cum_Units', header: 'Cum.Units', width: '10em', format: true, align: true },
        { field: 'nDivReinv', header: 'Div Reinv', width: '10em', format: true, align: true },
        { field: 'nDivPur', header: 'Div Pur', width: '10em', format: true, align: true },
        { field: 'nDays', header: 'Days', width: '7em', format: true, align: true },
        { field: 'nCurrValue', header: 'Curr Value', width: '10em', format: true, align: true },
        { field: 'sCategory', header: 'Short / Long Term', width: '10em', align: true },
    ]
    public FamilyWiseAllocationColumn = [
        { field: 'sClientName', header: 'Client Name', width: '10em', headerFilter: "sClientName", TotalColumn: 'sClientName' },
        { field: 'nInvestment', header: 'Investment', width: '12em', format: true, align: true, headerFilter: "nInvestment", TotalColumn: 'sClientName' },
        { field: 'Divi_Paid', header: 'Dividend Paid', width: '12em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'Divi_ReInv', header: 'Dividend Reinvestment', width: '12em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'CurVal', header: 'Cur.Val', width: '10em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'GainLoss', header: 'Gain/Loss', width: '10em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'AbsReturn', header: 'Abs.Return', width: '12em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'CAGR', header: 'XIRR', width: '12em', format: true, align: true, TotalColumn: 'sClientName' }

    ]
    public FolioWiseSummaryForFamilyColumn = [
        { field: 'sScheme', header: 'Scheme Name', width: '23em', tooltip: true, headerFilter: "sScheme", TotalColumn: 'sScheme' },
        { field: 'sFolio_No', header: 'Folio No', width: '12em', align: true, TotalColumn: 'sScheme' },
        { field: 'Category', header: 'Scheme Type', width: '10em', tooltip: true, headerFilter: "Category", TotalColumn: 'sScheme' },
        { field: 'dtStartDate', header: 'Start Date', width: '12em', TotalColumn: 'sScheme' },
        { field: 'nInvestment', header: 'Investment', width: '7em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'nUnits', header: 'Units', width: '10em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'nPurPrice', header: 'Pur.NAV', width: '5em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'Divi_Paid', header: 'Dividend Paid', width: '10em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'Divi_ReInv', header: 'Dividend Reinvestment', width: '10em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'Current_NAV', header: 'Cur.NAV', width: '10em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'CurVal', header: 'Cur.Val', width: '7em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'GainLoss', header: 'Gain/Loss', width: '7em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'AbsReturn', header: 'Abs.Return', width: '10em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'CAGR', header: 'XIRR', width: '12em', format: true, align: true, TotalColumn: 'sScheme' }
    ]

    public FamilyWiseAllocationReportColumn = [
        { field: 'sClientName', header: 'Client Name', width: '12em', tooltip: true, headerFilter: "sClientName", TotalColumn: 'sClientName' },
        { field: 'nInvestment', header: 'Investment', width: '10em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'Divi_Paid', header: 'Dividend Paid', width: '10em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'Divi_ReInv', header: 'Dividend Reinvestment', width: '10em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'CurVal', header: 'Cur.Val', width: '10em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'GainLoss', header: 'Gain/Loss', width: '10em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'AbsReturn', header: 'Abs.Return', width: '12em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'CAGR', header: 'XIRR', width: '12em', format: true, align: true, TotalColumn: 'sClientName' }
    ]

    public FolioWiseSummaryReportForFamilyColumn = [
        { field: 'sScheme', header: 'Scheme Name', width: '28em', tooltip: true, headerFilter: "sScheme", TotalColumn: 'sScheme' },
        { field: 'sFolio_No', header: 'Folio No', width: '10em', align: true, TotalColumn: 'sScheme' },
        { field: 'Category', header: 'Scheme Type', width: '12em', tooltip: true, headerFilter: "Category", TotalColumn: 'sScheme' },
        { field: 'dtStartDate', header: 'Start Date', width: '12em', TotalColumn: 'sScheme' },
        { field: 'nInvestment', header: 'Investment', width: '10em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'nUnits', header: 'Units', width: '10em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'nPurPrice', header: 'Pur.NAV', width: '10em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'Divi_Paid', header: 'Dividend Paid', width: '12em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'Divi_ReInv', header: 'Dividend Reinvestment', width: '16em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'Current_NAV', header: 'Cur.NAV', width: '10em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'CurVal', header: 'Cur.Val', width: '8em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'GainLoss', header: 'Gain/Loss', width: '9em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'AbsReturn', header: 'Abs.Return', width: '11em', format: true, align: true, TotalColumn: 'sScheme' },
        { field: 'CAGR', header: 'XIRR', width: '12em', format: true, align: true, TotalColumn: 'sScheme' }
    ]
    public ClientSchemeWiseFamilyTransactionColumn = [
        { field: 'nTradDate', header: 'Trad Date', width: '12em' },
        { field: 'sTrxn_Type', header: 'Trxn_Type', width: '21em', headerFilter: "sTrxn_Type" },
        { field: 'nAmount', header: 'Amount', width: '12em', format: true, align: true },
        { field: 'nUnits', header: 'Units', width: '12em', format: true, align: true },
        { field: 'Trxn_NAV', header: 'Tran. NAV', width: '12em', format: true, align: true },
        { field: 'Cum_Units', header: 'Cum.Units', width: '12em', format: true, align: true },
        { field: 'nDivReinv', header: 'Div Reinv', width: '12em', format: true, align: true },
        { field: 'nDivPur', header: 'Div Pur', width: '12em', format: true, align: true },
        { field: 'nDays', header: 'Days', width: '5em', format: true, align: true },
        { field: 'nCurrValue', header: 'Curr Value', width: '13em', format: true, align: true }
    ]

    public ClientWiseSchemeSIPDetailsColumn = [
        { field: 'sScheme_Name', header: 'Scheme Name', width: '28em', tooltip: true, headerFilter: "sScheme_Name", TotalColumn: 'sScheme_Name' },
        { field: 'sFolio_No', header: 'Folio No', width: '13em', align: true, TotalColumn: 'sScheme_Name' },
        { field: 'SIP_Frequency', header: 'Frequency', width: '10em', align: true, TotalColumn: 'sScheme_Name' },
        { field: 'SIPAmount', header: 'Installment', width: '13em', format: true, align: true, headerFilter: "SIPAmount", TotalColumn: 'sScheme_Name' },
        { field: 'Reg_Date', header: 'Reg Date', width: '12em', TotalColumn: 'sScheme_Name' },
        { field: 'Start_Date', header: 'Start Date', width: '12em', TotalColumn: 'sScheme_Name' },
        { field: 'End_Date', header: 'End Date', width: '12em', TotalColumn: 'sScheme_Name' },
        { field: 'Deduction_Date', header: 'Deduction Date', width: '13em', TotalColumn: 'sScheme_Name' },
    ]
    public ClientWiseSchemeSIPDetailsColumnDist = [
        { field: 'sScheme_Name', header: 'Scheme Name', width: '28em', tooltip: true, headerFilter: "sScheme_Name", TotalColumn: 'sScheme_Name' },
        { field: 'sFolio_No', header: 'Folio No', width: '13em', align: true, TotalColumn: 'sScheme_Name' },
        { field: 'SIP_Frequency', header: 'Frequency', width: '10em', align: true, TotalColumn: 'sScheme_Name' },
        { field: 'SIPAmount', header: 'Installment', width: '13em', format: true, align: true, headerFilter: "SIPAmount", TotalColumn: 'sScheme_Name' },
        { field: 'Reg_Date', header: 'Reg Date', width: '12em', TotalColumn: 'sScheme_Name' },
        { field: 'Start_Date', header: 'Start Date', width: '12em', TotalColumn: 'sScheme_Name' },
        { field: 'End_Date', header: 'End Date', width: '12em', TotalColumn: 'sScheme_Name' },
        { field: 'Deduction_Date', header: 'Deduction Date', width: '13em', TotalColumn: 'sScheme_Name' },
        { field: 'Delete', header: 'Delete', width: '6em', btnClass: 'fa fa-trash bttn-delete', showButtonLabel: true, tooltip: true, Type: 'Button', FieldName: 'Delete' }
    ]
    public ClientWiseSchemeSIPTransactionColumn = [
        { field: 'sTrxn_Type', header: 'Transaction Type', width: '10em', headerFilter: "sTrxn_Type" },
        { field: 'nTradDate', header: 'Transaction Date', width: '13em' },
        { field: 'nUnits', header: 'Transaction Units', width: '12em', format: true, align: true },
        { field: 'nAmount', header: 'Transaction Amount', width: '12em', format: true, align: true },
        { field: 'sRemarks', header: 'Remarks', width: '28em', tooltip: true },
    ]

    public BrokerageDataColumn = [
        { field: 'sClientName', width: '13em', header: 'Investor Name', tooltip: true, headerFilter: "sClientName" },
        { field: 'sPROD_NAME', width: '24em', header: 'Scheme', tooltip: true, headerFilter: "sPROD_NAME" },
        { field: 'sFOLIO_NO', width: '12em', header: 'Folio No', align: true },
        { field: 'Upfront', width: '10em', header: 'Upfront', align: true },
        { field: 'B15', width: '10em', header: 'B30', align: true },
        { field: 'Trail', width: '10em', header: 'Trail', align: true },
        { field: 'TransCharge', width: '12em', header: 'Trans Charges', format: true, align: true },
        { field: 'Clawback', width: '10em', header: 'Clawback', format: true, align: true },
        { field: 'Total', width: '10em', header: 'Total', format: true, align: true }

    ]

    public BrokerageDataDetailColumns = [
        { field: 'Brokg_Type', header: 'Brokerage Type', width: '12em', tooltip: true, headerFilter: "Brokg_Type" },
        { field: 'TranNo', header: 'Tran No', width: '11em', align: true },
        { field: 'Trans_Date', header: 'Trans Date', width: '10em' },
        { field: 'Brokerage_Desc', header: 'Brokerage Desc', width: '12em', format: true, tooltip: true },
        { field: 'Amount', header: 'Amount', width: '9em', align: true },
        { field: 'Brokg_Rate', header: 'Brokerage Rate', width: '12em', format: true, align: true },
        { field: 'Brokg_Amount', header: 'Brokerage Amt', width: '12em', format: true, align: true },
        { field: 'From_Date', header: 'From Date', width: '11em' },
        { field: 'To_Date', header: 'To Date', width: '11em' },
        { field: 'Average_Assets', header: 'Avg Assets', width: '10em', format: true, align: true },
        { field: 'Remarks', header: 'Remarks', width: '15em' }
    ]

    public LifeInsuranceGridDataColumns = [
        { field: 'sPolicyHolder', header: 'Policy Holder Name', width: '20em', headerFilter: "sPolicyHolder", tooltip: true },
        { field: 'sCompanyName', header: 'Company Name', width: '25em', headerFilter: "sCompanyName", tooltip: true },
        { field: 'sPolicyNumber', header: 'Policy Number', width: '20em', headerFilter: "sPolicyNumber", tooltip: true },
        { field: 'sPlanName', header: 'Plan Name', width: '25em', headerFilter: "sPlanName", tooltip: true },
        { field: 'sInsuranceType', header: 'Insurance Type', width: '10em' },
        { field: 'sPlanCategory', header: 'Plan Category', width: '10em' },
        { field: 'sProductUIN', header: 'UIN', width: '9em', align: true },
        { field: 'sPolicyStatus', header: 'Policy Status', width: '13em' },
        { field: 'nSumAssured', header: 'Sum Assured', width: '13em', format: true, align: true },
        { field: 'nMaturityAmount', header: 'Maturity Amount', width: '13em', format: true, align: true },
        { field: 'sPremiumFreq', header: 'Frequenecy', width: '9em', align: true },
        { field: 'nPremiumAmount', header: 'Prem Amount', width: '13em', format: true, align: true },
        { field: 'nPolicyCommenceDate', header: 'Commence Date', width: '9em' },
        { field: 'nPolicyMaturityDate', header: 'Maturity Date', width: '9em' },
        { field: 'nPremiumPayableDate', header: 'Prem Payable Date', width: '9em' },
        { field: 'sRiders', header: 'Riders', width: '25em' },
        // { field: 'Edit', header: 'Edit', width: '13em'},
        // { field: 'Delete', header: 'Delete', width: '13em' }
    ]

    public HealthInsuranceGridDataColumns = [
        { field: 'sPolicyHolder', header: 'Policy Holder Name', width: '20em', headerFilter: "sPolicyHolder", tooltip: true },
        { field: 'sCompanyName', header: 'Company Name', width: '25em', headerFilter: "sCompanyName", tooltip: true },
        { field: 'sPolicyNumber', header: 'Policy Number', width: '20em', headerFilter: "sPolicyNumber", tooltip: true },
        { field: 'sPlanName', header: 'Plan Name', width: '25em', headerFilter: "sPlanName", tooltip: true },
        { field: 'sPolicyCategory', header: 'Policy Category', width: '10em' },
        { field: 'sProductUIN', header: 'Product UIN', width: '9em', align: true },
        { field: 'sPolicyStatus', header: 'Policy Status', width: '13em' },
        { field: 'nSumAssured', header: 'Sum Assured', width: '13em', format: true, align: true },
        { field: 'sPremiumFreq', header: 'Premium Frequenecy', width: '13em', align: true },
        { field: 'nPremiumAmount', header: 'Premium Amount', width: '13em', format: true, align: true },
        { field: 'nPolicyCommenceDate', header: 'Policy Commence Date', width: '9em' },
        { field: 'nRenewalDate', header: 'Renewal Date', width: '9em', align: true },
    ]

    public MotorInsuranceGridDataColumns = [
        { field: 'sPolicyHolder', header: 'Policy Holder Name', width: '20em', headerFilter: "sPolicyHolder", tooltip: true },
        { field: 'sCompanyName', header: 'Company Name', width: '25em', headerFilter: "sCompanyName", tooltip: true },
        { field: 'sPolicyNumber', header: 'Policy Number', width: '20em', headerFilter: "sPolicyNumber", tooltip: true },
        { field: 'sPlanName', header: 'Plan Name', width: '25em', headerFilter: "sPlanName", tooltip: true },
        { field: 'sPolicyCategory', header: 'Policy Category', width: '10em' },
        { field: 'sProductUIN', header: 'Product UIN', width: '9em', align: true },
        { field: 'sPolicyStatus', header: 'Policy Status', width: '13em' },
        { field: 'sTypeOfCover', header: 'Type of Cover', width: '9em' },
        { field: 'nInsuranceDeclaredValue', header: 'Insured Declared Value', width: '13em', format: true, align: true },
        { field: 'sPremiumFreq', header: 'Premium Frequenecy', width: '13em', align: true },
        { field: 'nPremiumAmount', header: 'Premium Amount', width: '13em', format: true, align: true },
        { field: 'nPolicyCommenceDate', header: 'Policy Commence Date', width: '9em' },
        { field: 'nRenewalDate', header: 'Renewal Date', width: '9em' },
        { field: 'sAddOnCover', header: 'Add On Cover', width: '9em' },
    ]

    public HomeInsuranceGridDataColumns = [
        { field: 'sPolicyHolder', header: 'Policy Holder', width: '20em', headerFilter: "sPolicyHolder", tooltip: true },
        { field: 'sCompanyName', header: 'Company Name', width: '25em', headerFilter: "sCompanyName", tooltip: true },
        { field: 'sPolicyNumber', header: 'Policy Number', width: '20em', headerFilter: "sPolicyNumber", tooltip: true },
        { field: 'sPlanName', header: 'Plan Name', width: '25em', headerFilter: "sPlanName", tooltip: true },
        { field: 'sCoverage', header: 'Coverage', width: '20em' },
        { field: 'sProductUIN', header: 'Product UIN', width: '9em', align: true },
        { field: 'sPolicyStatus', header: 'Policy Status', width: '13em' },
        { field: 'sBuildingType', header: 'Building Type', width: '9em' },
        { field: 'nInsuranceDeclaredValue', header: 'Insurance Declare Value', width: '13em', format: true, align: true },
        { field: 'nContentValue', header: 'Content Value', width: '13em', align: true },
        { field: 'nPremiumAmount', header: 'Premium Amount', width: '13em', format: true, align: true },
        { field: 'sPremiumFreq', header: 'Premium Frequency', width: '13em', align: true },
        { field: 'nPolicyCommenceDate', header: 'Policy Commence Date', width: '9em' },
        { field: 'nRenewalDate', header: 'Renewal Date', width: '9em' },
    ]

    public DividentColumns = [
        { field: 'SR_', header: 'Sr No.', width: '7em' },
        { field: 'sSchemeName', header: 'Scheme Name', width: '28em', headerFilter: "sSchemeName", tooltip: true },
        { field: 'sFolio_No', header: 'Folio No', width: '13em', align: true },
        { field: 'Divi_Paid', header: 'Dividend Paid', width: '13em', format: true, align: true },
        { field: 'Divi_ReInv', header: 'Dividend Reinvestment', width: '15em', format: true, align: true }
    ]
    public DividentTransColumns = [
        { field: 'SR_', header: 'SR', width: '7em' },
        { field: 'dtTradDate', header: 'Trxn Date', width: '13em' },
        { field: 'sTrxn_Type', header: 'Description', width: '28em', tooltip: true, align: true },
        { field: 'nAmount', header: 'Amount', width: '13em', format: true, align: true }
    ]
    public TransactionSummary = [
        { field: 'sTrxn_Type', header: 'Transaction Type', headerFilter: "sTrxn_Type" },
        { field: 'nTrxn_No', header: 'No. Of Transactions', align: true },
        { field: 'nAmount', header: 'Amount', format: true, align: true }
    ]
    public FamilyDetails = [
        { field: 'sClientName', header: 'Family Head Name', headerFilter: "sClientName" },
        { field: 'nPAN', header: 'PAN No.' }

    ]
    public FamilyCreateDetails = [
        // { field: 'id', header: 'id' },
        { field: 'field', header: 'Client Name' },
        { field: 'pan', header: 'PAN No.' }
    ]

    public ClientMerginIndexColumn = [
        { field: 'sClientName', header: 'Client Name', headerFilter: 'sClientName' },
        { field: 'nPAN', header: 'PAN No', headerFilter: 'nPAN' },
        { field: 'sEmailId', header: 'Email Id' },
        { field: 'sLoginId', header: 'Login Id' }
    ]
    public ClientWithoutNomineeColumn = [

        { field: 'sInv_Name', header: 'Client Name', width: '25em', headerFilter: "sInv_Name", tooltip: true },
        { field: 'sFoliochk', header: 'Folio No.', width: '10em' },
        { field: 'sJoint1_Name', header: 'Joint Name1', width: '12em', tooltip: true },
        { field: 'sJoint2_Name', header: 'Joint Name2', width: '12em', tooltip: true },
        { field: 'sB_Address1', header: 'Address1', width: '12em', tooltip: true },
        { field: 'sB_Address2', header: 'Address2', width: '12em', tooltip: true },
        { field: 'sB_Address3', header: 'Address3', width: '12em', tooltip: true },
        { field: 'sB_City', header: 'City', width: '10em' },
        { field: 'sB_Pincode', header: 'Pincode', width: '10em' }
    ]

    public MessageBoardListColumn = [
        { field: 'sMessageText', header: 'Message', headerFilter: 'sMessageText', tooltip: true },
        { field: 'sFile1Name', header: 'File1 Name', headerFilter: 'sFile1Name', tooltip: true },
        { field: 'sFile2Name', header: 'File2 Name', headerFilter: 'sFile2Name', tooltip: true },
        { field: 'sFile3Name', header: 'File3 Name', headerFilter: 'sFile3Name', tooltip: true },
        { field: 'nCreatedOn', header: 'Created On' },
        { field: 'nValidTill', header: 'Valid Till' }
    ]
    // updated
    public ClientMasterIndexColumns = [
        { field: 'sClientName', header: 'Client Name', headerFilter: 'sClientName', width: '10em', tooltip: true },
        { field: 'sEmailId', header: 'Email Id', headerFilter: 'sEmailId', width: '10em' },
        { field: 'sPhoneNo', header: 'Phone No', width: '9em', headerFilter: 'sPhoneNo', },
        { field: 'nPAN', header: 'PAN No', headerFilter: 'nPAN', width: '9.5em' },
        { field: 'sCity', header: 'City', width: '10em' },
        { field: 'nPincode', header: 'PinCode', width: '8em' },
        { field: 'sLoginId', header: 'Login Id', headerFilter: 'sLoginId', width: '9em' },
        { field: 'sRMName', header: 'RM Name', headerFilter: 'sClientName', width: '9em', tooltip: true },
        { field: 'sBranchName', header: 'Branch Name', headerFilter: 'sClientName', width: '9em', tooltip: true },
        { field: 'sAgentName', header: 'Partner Name', headerFilter: 'sClientName', width: '9em', tooltip: true },
        { field: 'sEUIN', header: 'EUIN', width: '6em' },
        { field: 'sDOB', header: 'Birth Date', width: '9em' },
        { field: 'sDOA', header: 'Anniversary Date', width: '9em' },
        { field: 'Family_Head', header: 'Family Head', headerFilter: 'Family_Head', width: '12em', tooltip: true },
        { field: 'Edit', header: 'Transaction Privileges', width: '6em', btnClass: 'fa fa-plus', showButtonLabel: true, tooltip: true, Type: 'Button', FieldName: 'Privileges' },
    ]

    public DashboadClientMasterIndexColumns = [
        { field: 'sClientName', header: 'Client Name', headerFilter: 'sClientName', width: '10em', tooltip: true },
        { field: 'sEmailId', header: 'Email Id', headerFilter: 'sEmailId', width: '10em' },
        { field: 'sPhoneNo', header: 'Phone No', width: '9em', headerFilter: 'sPhoneNo', },
        { field: 'nPAN', header: 'PAN No', headerFilter: 'nPAN', width: '9.5em' },
        { field: 'sCity', header: 'City', width: '10em' },
        { field: 'nPincode', header: 'PinCode', width: '8em' },
        { field: 'sLoginId', header: 'Login Id', headerFilter: 'sLoginId', width: '9em' },
        { field: 'sRMName', header: 'RM Name', headerFilter: 'sClientName', width: '9em', tooltip: true },
        { field: 'sBranchName', header: 'Branch Name', headerFilter: 'sClientName', width: '9em', tooltip: true },
        { field: 'sAgentName', header: 'Partner Name', headerFilter: 'sClientName', width: '9em', tooltip: true },
        { field: 'sEUIN', header: 'EUIN', width: '6em' },
        { field: 'sDOB', header: 'Birth Date', width: '9em' },
        { field: 'sDOA', header: 'Anniversary Date', width: '9em' },
        { field: 'Family_Head', header: 'Family Head', headerFilter: 'Family_Head', width: '12em', tooltip: true },
        { field: 'Send Mail', header: 'Send Mail', width: '6em', btnClass: 'fa fa-birthday-cake bttn-edit', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'SendMail' },
    ]


    public FolioDetailsIndexColumns = [
        { field: 'sClientName', header: 'Client Name', headerFilter: 'sClientName', width: '20em', tooltip: true },
        { field: 'sFolio_No', header: 'Folio No', headerFilter: 'sFolio_No', width: '16em', tooltip: true },
        { field: 'sPAN', header: 'Pan No', headerFilter: 'sPAN', width: '16em', tooltip: true, NotTitleCase: true },
        { field: 'sScheme_Name', header: 'Scheme Name', headerFilter: 'sScheme_Name', width: '20em', tooltip: true },
        { field: 'sAC_No', header: 'Account No', headerFilter: 'sAC_No', width: '10em', tooltip: true },
        { field: 'sAC_Type', header: 'Account Type', headerFilter: 'sAC_Type', width: '11em', tooltip: true },
        { field: 'sIFSC_Code', header: 'IFSC Code', headerFilter: 'sIFSC_Code', width: '11em', tooltip: true, NotTitleCase: true },
        { field: 'sBank_Name', header: 'Bank Name', headerFilter: 'sBank_Name', width: '15em', tooltip: true },
        { field: 'sBranch', header: 'Branch', headerFilter: 'sBranch', width: '12em', tooltip: true },
        { field: 'sJoint1_Name', header: 'Joint Holder 1', headerFilter: 'sJoint1_Name', width: '12em', tooltip: true },
        { field: 'sJoint2_Name', header: 'Joint Holder 2', headerFilter: 'sJoint2_Name', width: '12em', tooltip: true }


    ]

    public ClientReturnsIndexColumns = [
        { field: 'sClientName', header: 'Client Name', headerFilter: 'sClientName', width: '20em', tooltip: true },
        { field: 'nInvestment', header: 'Investment', headerFilter: 'nInvestment', width: '16em', tooltip: true, format: true, align: true },
        { field: 'Dividend Paid', header: 'Dividend Paid', headerFilter: 'Dividend Paid', width: '12em', tooltip: true, format: true, align: true },
        { field: 'Current Value', header: 'Current Value', headerFilter: 'Current Value', width: '12em', tooltip: true, format: true, align: true },
        { field: 'GainLoss', header: 'Gain / Loss', headerFilter: 'GainLoss', width: '12em', tooltip: true, format: true, align: true },
        { field: 'AbsReturn', header: 'Absolute Return', headerFilter: 'AbsReturn', width: '16em', tooltip: true, NotTitleCase: true, format: true, align: true },
        { field: 'CAGR', header: 'CAGR / XIRR', headerFilter: 'CAGR', width: '12em', tooltip: true, format: true, align: true }
    ]
    public ClientMasterIndexColumnsIFA = [
        { field: 'sClientName', header: 'Client Name', headerFilter: 'sClientName', width: '12em', tooltip: true },
        { field: 'sEmailId', header: 'Email Id', headerFilter: 'sEmailId', width: '16em' },
        { field: 'sPhoneNo', header: 'Phone No', width: '9em' },
        { field: 'nPAN', header: 'PAN No', headerFilter: 'nPAN', width: '9.5em' },
        { field: 'sCity', header: 'City', width: '10em' },
        { field: 'nPincode', header: 'PinCode', width: '8em' },
        { field: 'sLoginId', header: 'Login Id', headerFilter: 'sLoginId', width: '9em' },
        { field: 'sEUIN', header: 'EUIN', width: '6em' },
        { field: 'sDOB', header: 'Birth Date', width: '9em' },
        { field: 'sDOA', header: 'Anniversary Date', width: '9em' },
        { field: 'Family_Head', header: 'Family Head', headerFilter: 'Family_Head', width: '12em', tooltip: true },
        { field: 'Edit', header: 'Transaction Privileges', width: '6em', btnClass: 'fa fa-plus', showButtonLabel: true, tooltip: true, Type: 'Button', FieldName: 'Privileges' },
    ]

    public NewFundOfferColumns = [
        { field: 'sNo', header: 'Sr No.', width: '7em' },
        { field: 'sSchemeName', header: 'Scheme Name', width: '28em', headerFilter: 'sSchemeName', tooltip: true },
        { field: 'sSchemeCategory', header: 'Category', width: '11em', headerFilter: 'sSchemeCategory', tooltip: true },
        { field: 'sSchemeType', header: 'Type', width: '7em' },
        { field: 'nNewFundLaunchDate', header: 'Launch Date', width: '12em' },
        { field: 'nNewFundOfferClosureDate', header: 'Offer Closure Date', width: '15em' },
        { field: 'nOfferPrice', header: 'Offer Price', width: '12em' },
    ]

    public NewFundOfferChildColumns = [
        { field: 'sMutualFundName', header: 'Mutual Fund', headerFilter: 'sMutualFundName', width: '20em', tooltip: true },
        { field: 'sSchemeName', header: 'Scheme Name', headerFilter: 'sSchemeName', width: '28em', tooltip: true },
        { field: 'sObjectiveOfScheme', header: 'Objective of Scheme', headerFilter: 'sObjectiveOfScheme', width: '30em', tooltip: true },
        { field: 'sSchemeType', header: 'Type', width: '7em' },
        { field: 'sSchemeCategory', header: 'Category', width: '7em' },
        { field: 'nNewFundLaunchDate', header: 'Launch Date', width: '9em' },
        { field: 'nNewFundEarliestClosureDate', header: 'Earliest Closure Date', width: '13em' },
        { field: 'nNewFundOfferClosureDate', header: 'Offer Closure Date', width: '13em' },
        { field: 'sIndicateLoadSeparately', header: 'Indicate Load Separately', width: '25em', tooltip: true },
        { field: 'nOfferPrice', header: 'Offer Price(Rs.)', width: '12em' },
        { field: 'sMinimumSubscriptionAmount', header: 'Minimum Subscription Amount', width: '28em' },
    ]

    public BranchMasterIndexColumn = [
        { field: 'sBranchName', header: 'Branch Name', width: '15em', headerFilter: 'sBranchName', tooltip: true },
        { field: 'sBranchAddress', header: 'Address', width: '20em', headerFilter: 'sBranchAddress', tooltip: true },
        { field: 'sCityName', header: 'City Name', width: '15em', headerFilter: 'sCityName', tooltip: true },
        { field: 'sEmailId', header: 'Email Id', width: '15em' },
        { field: 'sPinCode', header: 'Pin Code', width: '9em' },
        { field: 'sLoginId', header: 'Login Id', width: '15em' },
    ]

    public RMMasterIndexColumn = [
        { field: 'sRMName', header: 'RM Name', width: '11em', headerFilter: 'sRMName', tooltip: true },
        { field: 'sRMAddress', header: 'Address', width: '16em', headerFilter: 'sRMAddress', tooltip: true },
        { field: 'sMobile', header: 'Mobile No', width: '10em' },
        { field: 'sLandline', header: 'Landline No', width: '10em', tooltip: true },
        { field: 'sEmailId', header: 'Email Id', width: '17em' },
        { field: 'nPAN', header: 'PAN No', width: '9em' },
        { field: 'sAadharNo', header: 'Aadhar No', width: '11em' },
        { field: 'sLoginId', header: 'Login Id', width: '17em' },
        { field: 'sSubBrokerCode', header: 'SubBroker Code', width: '11em' },
        { field: 'sSubBrokerARN', header: 'SubBroker ARN', width: '11em' },
        { field: 'sEUIN', header: 'EUIN', width: '7em' },
        { field: 'nIsActive', header: 'Is Active', width: '8em' },
    ]

    public AgentMasterIndexColumn = [
        { field: 'sAgentName', header: 'Partner Name', width: '11em', headerFilter: 'sAgentName', tooltip: true },
        { field: 'sAgentAddress', header: 'Address', width: '11em', headerFilter: 'sAgentAddress', tooltip: true },
        { field: 'sMobile', header: 'Mobile No', width: '11em', },
        { field: 'sLandline', header: 'Landline No', width: '11em', },
        { field: 'sEmailId', header: 'Email Id', width: '16em' },
        { field: 'nPAN', header: 'PAN No', width: '11em' },
        { field: 'sAadharNo', header: 'Aadhar No', width: '11em' },
        { field: 'sLoginId', header: 'Login Id', width: '16em' },
        { field: 'sSubBrokerCode', header: 'SubBroker Code', width: '12em' },
        { field: 'sSubBrokerARN', header: 'SubBroker ARN', width: '12em' },
        { field: 'sEUIN', header: 'EUIN', width: '7em' },
        { field: 'nIsActive', header: 'Is Active', width: '6em' }
    ]

    public BrokerageSharingDataColumn = [
        { field: 'sAgentName', width: '11em', header: 'Partner Name', headerFilter: 'sAgentName', tooltip: true },
        { field: 'sClientName', width: '11em', header: 'Investor Name', headerFilter: 'sClientName', tooltip: true },
        { field: 'sPROD_NAME', width: '20em', header: 'Scheme', headerFilter: 'sPROD_NAME', tooltip: true },
        { field: 'sFOLIO_NO', width: '8em', header: 'Folio No', align: true },
        { field: 'Upfront', width: '8em', header: 'Upfront', format: true, align: true },
        { field: 'B15', width: '7em', header: 'B30', format: true, align: true },
        { field: 'Trail', width: '7em', header: 'Trail', format: true, align: true },
        { field: 'TransCharge', width: '11em', header: 'Trans Charges', format: true, align: true },
        { field: 'Clawback', width: '9em', header: 'Clawback', format: true, align: true },
        { field: 'Total', width: '6em', header: 'Total', format: true, align: true },
        { field: 'sBranchName', width: '11em', header: 'Branch Name', tooltip: true },
        { field: 'sRMName', width: '10em', header: 'RM Name', tooltip: true },
        { field: 'sRateCardName', width: '12em', header: 'RateCard Names', tooltip: true },
        { field: 'nB30Per', width: '10em', header: 'B30 %', tooltip: true },
        { field: 'nUpfrontPer', width: '10em', header: 'Upfront %', tooltip: true },
        { field: 'nTrnChargePer', width: '12em', header: 'Trancharge %', tooltip: true },
        { field: 'nTrailPer', width: '10em', header: 'Trail %', tooltip: true },
        { field: 'nClawbackPer', width: '10em', header: 'Clawback %', tooltip: true }
    ]
    public BrokerageSharingDataDetailsColumns = [
        { field: 'Brokg_Type', header: 'Brokerage Type', width: '10em', tooltip: true },
        { field: 'TranNo', header: 'Tran No', width: '10em', tooltip: true },
        { field: 'Trans_Date', header: 'Trans Date', width: '80px', tooltip: true },
        { field: 'Brokerage_Desc', header: 'Brokerage Desc', width: '10em', tooltip: true },
        { field: 'Amount', header: 'Amount', width: '7em', cellClass: 'grid_cellAlign' },
        { field: 'Brokg_Rate', header: 'Brokerage Rate', width: '10em', format: true, align: true },
        { field: 'Brokg_Amount', header: 'Brokerage Amt', width: '10em', format: true, align: true },
        { field: 'From_Date', header: 'From Date', width: '80px', cellClass: 'grid_cellAlign' },
        { field: 'To_Date', header: 'To Date', width: '80px', cellClass: 'grid_cellAlign' },
        { field: 'Average_Assets', header: 'Avg Assets', width: '80px', cellClass: 'grid_cellAlign' },
        { field: 'Remarks', header: 'Remarks', width: '12em' }
    ]
    public SchedulerMasterColumns = [
        { field: 'sScheduleName', header: 'Schedule Name', width: '20em', headerFilter: 'sScheduleName', tooltip: true },
        { field: 'sSheduleType', header: '	Schedule Type', width: '20em', headerFilter: 'nScheduleType', tooltip: true },
        { field: 'sReportType', header: 'Report Type', width: '15em', headerFilter: 'sReportType' },
        { field: 'sFreq', header: '	Frequency', width: '10em', },
    ]

    public DashAUMReportListColumn = [

        { field: 'sClientName', header: 'Client Name', width: '11em', headerFilter: 'sClientName', tooltip: true, TotalColumn: 'sClientName' },
        { field: 'AUM', header: 'AUM', width: '11em', format: true, align: true, TotalColumn: 'sClientName' },
        { field: 'Folios', header: 'No of Folio', width: '7em', align: true, TotalColumn: 'sClientName' },
        { field: 'sRMName', header: 'RM Name', width: '5em', headerFilter: 'sRMName', tooltip: true, TotalColumn: 'sClientName' },

    ]
    public AUMReconcilationListColumn = [

        { field: 'sScheme', header: 'Scheme Name', width: '12em', headerFilter: 'sScheme', tooltip: true, TotalColumn: 'sScheme' },
        { field: 'sClientName', header: 'Client Name', width: '10em', headerFilter: 'sClientName', tooltip: true, TotalColumn: 'sClientName' },
        { field: 'sBrokCode', header: 'Broker Code', width: '10em', headerFilter: 'sBrokCode', tooltip: true, TotalColumn: 'sBrokCode' },
        { field: 'sProd_Code', header: 'Product Code', width: '12em', headerFilter: 'sProd_Code', tooltip: true, TotalColumn: 'sProd_Code' },
        { field: 'sFolio_No', header: 'Folio No', width: '10em', headerFilter: 'sFolio_No', tooltip: true, TotalColumn: 'sFolio_No' },
        { field: 'nInvestment', header: 'Investment', width: '10em', format: true, align: true, TotalColumn: 'AUMInvestment' },
        { field: 'nUnits', header: 'Units', width: '10em', format: true, align: true, TotalColumn: 'AUMUnits' },
        { field: 'AUMInvestment', header: 'AUM Investment', width: '12em', format: true, align: true, TotalColumn: 'AUMInvestment' },
        { field: 'AUMUnits', header: 'AUM Units', width: '10em', align: true, TotalColumn: 'AUMUnits' },
        { field: 'sAsOnAUMDate', header: 'AUM Date', width: '10em', align: true, TotalColumn: 'sAsOnAUMDate' },
        { field: 'sAsOnDate', header: 'As On Date', width: '10em', align: true, TotalColumn: 'sAsOnDate' },
        { field: 'sOutput', header: 'Output', width: '10em', tooltip: true, TotalColumn: 'sOutput' },





    ]
    public DashClientReportListColumn = [
        { field: 'sClientName', header: 'Client Name', width: '11em', headerFilter: 'sClientName', tooltip: true },
        { field: 'sEmailId', header: 'Email Id', width: '17em', headerFilter: 'sEmailId', tooltip: true },
        { field: 'sPhoneNo', header: 'Phone No.', width: '10em' },
        { field: 'sCity', header: 'City', width: '12em', tooltip: true },
        { field: 'nPincode', header: 'Pin Code', width: '10em' },
        { field: 'nPAN', header: 'PAN', width: '11em' }
    ]
    public DashNonPanClientReportListColumn = [
        { field: 'sClientName', header: 'Client Name', width: '15em', headerFilter: 'sClientName', tooltip: true },
        { field: 'sEmailId', header: 'Email Id', width: '18em' },
        { field: 'sPhoneNo', header: 'Phone No.', width: '10em' },
        { field: 'sCity', header: 'City', width: '6em', tooltip: true },
        { field: 'nPincode', header: 'Pin Code', width: '6em' },
        { field: 'sRMName', header: 'RM Name', width: '8em', headerFilter: 'sRMName', tooltip: true },
    ]
    public PurchaseLogColumns = [
        { field: 'DateTime', header: 'Date Time', width: '20em', tooltip: true, datetime: true },
        { field: 'nOrderId', header: 'Order Id', width: '12em', tooltip: true },
        { field: 'sClientCode', header: 'Client Code', width: '12em', tooltip: true },
        { field: 'sClientName', header: 'Client Name', width: '20em', headerFilter: 'sClientName', tooltip: true },
        { field: 'sFolioNo', header: 'Folio No', width: '10em', tooltip: true },
        { field: 'sSchemeCode', header: 'Scheme Code', width: '10em', headerFilter: 'sSchemeCode', tooltip: true },
        { field: 'Qty', header: 'Qty', width: '10em', tooltip: true },
        { field: 'Amount', header: 'Amount', width: '10em', tooltip: true },
        { field: 'sBuySell', header: 'BuySell', width: '10em', tooltip: true }
    ]

    public TransactionLogColumns = [
        { field: 'ClientName', header: 'Client Name', width: '20em', headerFilter: 'ClientName', tooltip: true },
        { field: 'DateTime', header: 'Date Time', width: '12em', tooltip: true, datetime: true },
        { field: 'ClientCode', header: 'Client Code', width: '10em', tooltip: true },
        { field: 'FolioNo', header: 'Folio No', width: '10em', tooltip: true },
        { field: 'OrderId', header: 'Order Id', width: '80px', tooltip: true, NotTitleCase: true },
        { field: 'TransactionType', header: 'Type', width: '10em', tooltip: true },
        { field: 'SchemeName', header: 'Scheme Name', width: '15em', tooltip: true },
        { field: 'ToSchemeName', header: 'To Scheme Name', width: '10em', tooltip: true },
        { field: 'Amount', header: 'Amount', width: '80px', tooltip: true, NotTitleCase: true },
        { field: 'Qty', header: 'Qty', width: '5em', tooltip: true },
        { field: 'OrderStatus', header: 'Order Status', width: '15em', btnClass: 'fa fa-refresh bttn-edit', showButtonLabel: true, tooltip: true, Type: 'Button', FieldName: 'Refresh' },
        { field: 'sCancelFlag', header: 'Cancel', width: '10em', btnClass: 'fa fa-close bttn-edit', showButtonLabel: false, tooltip: true, TempVal: 'OrderStatus', Type: 'Button', FieldName: 'Cancel' }
    ]

    public OnBoardingLogColumns = [
        { field: 'sClientName', header: 'Name', width: '20em', headerFilter: 'sClientName', tooltip: true },
        { field: 'UCCDate', header: 'Date', width: '10em', tooltip: true },
        { field: 'AgentRmName', header: 'Agent/RM', width: '15em', tooltip: true },
        { field: 'nPAN', header: 'Pan Number', width: '10em', tooltip: true },
        { field: 'sEmailId', header: 'Email', width: '15em', tooltip: true },
        { field: 'sDomMobNo', header: 'Phone', width: '12em', tooltip: true },
        { field: 'sLoginId', header: 'Login ID', width: '15em', tooltip: true },
        { field: 'xPDFData', header: 'AOF / Mandate-Download', width: '10em', tooltip: true, btnClass: 'fa fa-download bttn-edit', showButtonLabel: false, Type: 'Button', FieldName: 'AOFMandateDownload' },
        { field: 'sUCCStatus', header: 'AOF', width: '10em', tooltip: true },
        { field: 'MandateStatus', header: 'Mandate', width: '10em', tooltip: true },
        { field: 'FatcaStatus', header: 'FATCA', width: '10em', tooltip: true },
        { field: 'TransactionBSE', header: 'Transaction BSE', width: '15em', tooltip: true },
        { field: 'TransactionRTA', header: 'Transaction RTA', width: '15em', tooltip: true }
    ]

    public PurchaseTransactionColumn = [
        { field: 'sInv_Name', header: 'Client Name', width: '9em', headerFilter: 'sInv_Name', tooltip: true },
        { field: 'sScheme', header: 'Scheme Name', width: '10em', headerFilter: 'sScheme', tooltip: true },
        { field: 'sFolio_No', header: 'Folio No', width: '7em', align: true },
        { field: 'nTransactionDate', header: 'Transaction Date', width: '6em' },
        { field: 'nAmount', header: 'Amount', width: '7em', format: true, align: true }
    ]
    public RedemptionTransactionColumn = [
        { field: 'sInv_Name', header: 'Client Name', width: '9em', headerFilter: 'sInv_Name', tooltip: true },
        { field: 'sScheme', header: 'Scheme Name', width: '10em', headerFilter: 'sScheme', tooltip: true },
        { field: 'sFolio_No', header: 'Folio No', width: '7em', align: true },
        { field: 'nTransactionDate', header: 'Transaction Date', width: '6em' },
        { field: 'nAmount', header: 'Amount', width: '7em', format: true, align: true }
    ]

    public NewSIPColumn = [
        { field: 'sInv_Name', header: 'Client Name', width: '9em', headerFilter: 'sInv_Name', tooltip: true },
        { field: 'sScheme_Name', header: 'Scheme Name', width: '10em', headerFilter: 'sScheme_Name', tooltip: true, NotTitleCase: true },
        { field: 'sFolio_No', header: 'Folio No', width: '7em', align: true },
        { field: 'nAmount', header: 'SIP Amt', width: '7em', format: true, align: true, headerFilter: 'nAmount' },
        { field: 'dtDueDate', header: 'Due Date', width: '6em' }
    ]
    public SIPDueColumn = [
        { field: 'sInv_Name', header: 'Client Name', width: '9em', headerFilter: 'sInv_Name', tooltip: true },
        { field: 'sScheme_Name', header: 'Scheme Name', width: '10em', headerFilter: 'sScheme_Name', tooltip: true, NotTitleCase: true },
        { field: 'sFolio_No', header: 'Folio No', width: '7em', align: true },
        { field: 'nAmount', header: 'SIP Amt', width: '7em', format: true, align: true, headerFilter: 'nAmount' },
        { field: 'dtDueDate', header: 'Due Date', width: '6em' }
    ]

    public SIPExpiryColumn = [
        { field: 'sInv_Name', header: 'Client Name', width: '9em', headerFilter: 'sInv_Name', tooltip: true },
        { field: 'sScheme_Name', header: 'Scheme Name', width: '10em', headerFilter: 'sScheme_Name', tooltip: true, NotTitleCase: true },
        { field: 'sFolio_No', header: 'Folio No', width: '7em', align: true },
        { field: 'nAmount', header: 'SIP Amt', width: '7em', format: true, align: true, headerFilter: 'nAmount' },
        { field: 'dtMaturityDate', header: 'Mature Date', width: '6em' }
    ]

    public OtherARNColumns = [
        { field: 'sClientName', header: 'Client Name', width: '12em', headerFilter: 'sClientName', tooltip: true },
        { field: 'sOtherARN', header: 'Other ARN', width: '10em', headerFilter: 'sOtherARN', tooltip: true },
        { field: 'sAMCName', header: 'AMC Name', width: '11em', headerFilter: 'sAMCName', tooltip: true, NotTitleCase: true },
        { field: 'sCategory', header: 'Category', width: '10em', headerFilter: 'sCategory', tooltip: true },
        { field: 'sSchemeName', header: 'Scheme Name', width: '16em', headerFilter: 'sCategory', tooltip: true },
        { field: 'sSchemeType', header: 'Scheme Type', width: '10em' },
        { field: 'sFolioNumber', header: 'Folio Number', width: '10em', align: true },
        { field: 'sTransactionType', header: 'Transaction Type', width: '12em' },
        { field: 'sTransactionDate', header: 'Transaction Date', width: '10em' },
        { field: 'nTransactionUnits', header: 'Units', width: '8em', align: true },
        { field: 'nTransactionAmount', header: 'Amount', width: '8em', align: true },
        { field: 'nTransactionNAV', header: 'NAV', width: '8em', align: true },
        { field: 'sRemarks', header: 'Remarks', width: '8em' },
        { field: 'Transaction', header: 'Transaction', width: '10em', btnClass: 'fa fa-money bttn-common', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Additional Purchase,Redemption,Switch-In,Switch-Out,SIP,STP-In,STP-Out,SWP' },
        { field: 'Edit', header: 'Edit', width: '6em', btnClass: 'fa fa-pencil bttn-edit', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Edit' },
        { field: 'Delete', header: 'Delete', width: '6em', btnClass: 'fa fa-trash bttn-delete', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Delete' }
    ]

    public DepositoryUnitsColumns = [
        { field: 'sClientName', header: 'Client Name', width: '12em', headerFilter: 'sClientName', tooltip: true },
        { field: 'sClientDPID', header: 'DPID', width: '10em', headerFilter: 'sClientDPID', tooltip: true },
        { field: 'sClientAccountNo', header: 'Account No', width: '10em', headerFilter: 'sClientAccountNo', tooltip: true },
        { field: 'sDepository', header: 'Depository', width: '10em', headerFilter: 'sDepository', tooltip: true },
        { field: 'sISINNumber', header: 'ISIN Number', width: '10em', headerFilter: 'sISINNumber', tooltip: true },
        { field: 'sAMCName', header: 'AMC Name', width: '11em', headerFilter: 'sAMCName', tooltip: true, NotTitleCase: true },
        { field: 'sCategory', header: 'Category', width: '10em', headerFilter: 'sCategory', tooltip: true },
        { field: 'sSchemeName', header: 'Scheme Name', width: '16em', headerFilter: 'sCategory', tooltip: true },
        { field: 'sSchemeType', header: 'Scheme Type', width: '10em' },
        { field: 'sFolioNumber', header: 'Folio Number', width: '10em', align: true },
        { field: 'sTransactionType', header: 'Transaction Type', width: '12em' },
        { field: 'sTransactionDate', header: 'Transaction Date', width: '10em' },
        { field: 'nTransactionUnits', header: 'Units', width: '8em', align: true },
        { field: 'nTransactionAmount', header: 'Amount', width: '8em', align: true },
        { field: 'nTransactionNAV', header: 'NAV', width: '8em', align: true },
        { field: 'sRemarks', header: 'Remarks', width: '8em' },
        { field: 'Transaction', header: 'Transaction', width: '10em', btnClass: 'fa fa-money bttn-common', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Additional Purchase,Redemption,Switch-In,Switch-Out,SIP,STP-In,STP-Out,SWP' },
        { field: 'Edit', header: 'Edit', width: '6em', btnClass: 'fa fa-pencil bttn-edit', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Edit' },
        { field: 'Delete', header: 'Delete', width: '6em', btnClass: 'fa fa-trash bttn-delete', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Delete' }
    ]

    public BondsColumns = [
        { field: 'sClientName', header: 'Client Name', width: '', headerFilter: 'sClientName', tooltip: true },
        { field: 'sAssetType', header: 'Asset Type', width: '', headerFilter: 'sAssetType', tooltip: true },
        { field: 'sReceiptNo', header: 'Receipt No', width: '', headerFilter: 'sReceiptNo', tooltip: true },
        { field: 'dtInvestmentDate', header: 'Investment Date', width: '', headerFilter: 'dtInvestmentDate', tooltip: true },
        { field: 'nInvestmentValue', header: 'Investment Amount', width: '', headerFilter: 'nInvestmentValue', tooltip: true },
        { field: 'nInterestRate', header: 'Investment Rate', width: '', headerFilter: 'nInterestRate', tooltip: true },
        { field: 'dtEndDate', header: 'End Date', width: '', headerFilter: 'dtEndDate', tooltip: true },
        { field: 'sInterestFreq', header: 'Interest Freq', width: '', headerFilter: 'sInterestFreq', tooltip: true },
        { field: 'nMaturityValue', header: 'Maturity Amount', width: '', headerFilter: 'nMaturityValue', tooltip: true },
        { field: 'Edit', header: 'Edit', width: '6em', btnClass: 'fa fa-pencil bttn-edit', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Edit' },
        { field: 'Delete', header: 'Delete', width: '6em', btnClass: 'fa fa-trash bttn-delete', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Delete' }

    ]
    public CommoditiesColumns = [
        { field: 'sClientName', header: 'Client Name', width: '', headerFilter: 'sClientName', tooltip: true },
        { field: 'sAssetType', header: 'Asset Type', width: '', headerFilter: 'sAssetType', tooltip: true },
        { field: 'dtInvestmentDate', header: 'Investment Date', width: '', headerFilter: 'dtInvestmentDate', tooltip: true },
        { field: 'nInvestmentValue', header: 'Investment Value', width: '', headerFilter: 'nInvestmentValue', tooltip: true },
        { field: 'nCurrentValue', header: 'Current Value', width: '', headerFilter: 'nCurrentValue', tooltip: true },
        { field: 'sRemarks', header: 'Remarks', width: '', headerFilter: 'sRemarks', tooltip: true },
        { field: 'Edit', header: 'Edit', width: '6em', btnClass: 'fa fa-pencil bttn-edit', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Edit' },
        { field: 'Delete', header: 'Delete', width: '6em', btnClass: 'fa fa-trash bttn-delete', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Delete' }
    ]
    public PostOfficeRetirementColumns = [
        { field: 'sClientName', header: 'Client Name', width: '', headerFilter: 'sClientName', tooltip: true, },
        { field: 'sAssetType', header: 'Asset Name', width: '', headerFilter: 'sAssetType', tooltip: true, },
        { field: 'nAccountNo', header: 'Account No', width: '', headerFilter: 'nAccountNo', tooltip: true, },
        { field: 'dtInvestmentDate', header: 'Investment Date', width: '', headerFilter: 'dtInvestmentDate', tooltip: true, },
        { field: 'nMonthlyContribution', header: 'Monthly Contribution', width: '', headerFilter: 'nMonthlyContribution', tooltip: true, },
        { field: 'nInvestmentValue', header: 'Investment Value', width: '', headerFilter: 'nInvestmentValue', tooltip: true, },
        { field: 'nInterestRate', header: 'Investment Rate', width: '', headerFilter: 'nInterestRate', tooltip: true, },
        { field: 'dtnEndDate', header: 'Maturity Date', width: '', headerFilter: 'dtnEndDate', tooltip: true, },
        { field: 'nMaturityValue', header: 'Maturity Value', width: '', headerFilter: 'nMaturityValue', tooltip: true, },
        { field: 'Edit', header: 'Edit', width: '6em', btnClass: 'fa fa-pencil bttn-edit', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Edit' },
        { field: 'Delete', header: 'Delete', width: '6em', btnClass: 'fa fa-trash bttn-delete', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Delete' }
    ]
    public SharesColumns = [
        { field: 'sClientName', header: 'Client Name', width: '', headerFilter: 'sClientName', tooltip: true, },
        { field: 'sStockName', header: 'Stock Name', width: '', headerFilter: 'sStockName', tooltip: true, },
        { field: 'dtPurchaseDate', header: 'Purchase Date', width: '', headerFilter: 'dtPurchaseDate', tooltip: true, },
        { field: 'nNoofShares', header: 'No of Shares', width: '', headerFilter: 'nNoofShares', tooltip: true, },
        { field: 'nAveragePrice', header: 'Average Price', width: '', headerFilter: 'nAveragePrice', tooltip: true, },
        { field: 'nInvestedValue', header: 'Invested Value', width: '', headerFilter: 'nInvestedValue', tooltip: true, },
        { field: 'nCurrentMarketPrice', header: 'Market Price', width: '', headerFilter: 'nCurrentMarketPrice' },
        { field: 'nCurrentValue', header: 'Market Value', width: '', headerFilter: 'nCurrentValue' },
        { field: 'Edit', header: 'Edit', width: '6em', btnClass: 'fa fa-pencil bttn-edit', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Edit' },
        { field: 'Delete', header: 'Delete', width: '6em', btnClass: 'fa fa-trash bttn-delete', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Delete' }
    ]
    public FixedIncomeColumns = [
        { field: 'sClientName', header: 'Client Name', width: '', headerFilter: 'sClientName', tooltip: true, },
        { field: 'sAssetType', header: 'Asset Type', width: '', headerFilter: 'sAssetType', tooltip: true, },
        { field: 'sBankName', header: 'Bank Name', width: '', headerFilter: 'sBankName', tooltip: true, },
        { field: 'sReceiptNo', header: 'Receipt No', width: '', headerFilter: 'sReceiptNo', tooltip: true, },
        { field: 'dtInvestmentDate', header: 'Investment Date', width: '', headerFilter: 'dtInvestmentDate', tooltip: true, },
        { field: 'nInvestmentValue', header: 'Investment Value', width: '', headerFilter: 'nInvestmentValue', tooltip: true, },
        { field: 'nTenureInMonths', header: 'Tenure in Months', width: '', headerFilter: 'nTenureInMonths', tooltip: true, },
        { field: 'nInterestRate', header: 'Interest Rate', width: '', headerFilter: 'nInterestRate', tooltip: true, },
        { field: 'nMaturityValue', header: 'Maturity Value', width: '', headerFilter: 'nMaturityValue', tooltip: true, },
        { field: 'Edit', header: 'Edit', width: '6em', btnClass: 'fa fa-pencil bttn-edit', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Edit' },
        { field: 'Delete', header: 'Delete', width: '6em', btnClass: 'fa fa-trash bttn-delete', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Delete' }
    ]
    public AllSchemeMainTableColumns = [
        { field: 'SchemeName', header: 'Scheme', width: '35em', headerFilter: 'SchemeName', tooltip: true, rowClick: true },
        { field: 'SchemeCategory', header: 'Category', width: '15em', headerFilter: 'SchemeCategory', tooltip: true },
        { field: 'nAUM', header: 'AUM', width: '8em', headerFilter: 'nAUM', format: true, align: true },
        { field: 'CurrentNAV', header: 'NAV', width: '8em', headerFilter: 'CurrentNAV', format: true, align: true },
        { field: 'sRiskGradeName', header: 'Risk', width: '10em', headerFilter: 'sRiskGradeName' },
        { field: 'nMonthlyChange', header: '1M', width: '7em', format: true, align: true },
        { field: 'nQuarterlyChange', header: '3M', width: '7em', format: true, align: true },
        { field: 'nHalfYearlyChange', header: '6M', width: '7em', format: true, align: true },
        { field: 'nYearlyChange', header: '1Y', width: '7em', format: true, align: true },
        { field: 'n3YearlyChange', header: '3Y', width: '7em', format: true, align: true },
        { field: 'n5YearlyChange', header: '5Y', width: '7em', format: true, align: true },
        { field: 'n10YearlyChange', header: '10Y', width: '7em', format: true, align: true }
    ]
    public PeerComparisionColumnsShort = [
        { field: 'SchemeName', header: 'Scheme', width: '25em', headerFilter: 'SchemeName', tooltip: true, rowClick: true },
        { field: 'nMonthlyChange', header: '1M', width: '5em', format: true, align: true },
        { field: 'nQuarterlyChange', header: '3M', width: '5em', format: true, align: true },
        { field: 'nHalfYearlyChange', header: '6M', width: '5em', format: true, align: true },
        { field: 'nYearlyChange', header: '1Y', width: '5em', format: true, align: true }
    ]
    public PeerComparisionColumnsLong = [
        { field: 'SchemeName', header: 'Scheme', width: '25em', headerFilter: 'SchemeName', tooltip: true, rowClick: true },
        { field: 'n3YearlyChange', header: '3Y', width: '5em', format: true, align: true },
        { field: 'n5YearlyChange', header: '5Y', width: '5em', format: true, align: true },
        { field: 'n10YearlyChange', header: '10Y', width: '5em', format: true, align: true },
        { field: 'n20YearlyChange', header: '20Y', width: '5em', format: true, align: true }
    ]

    public MArketCapColumns = [
        { field: 'MarketCapitalization', header: 'Market Cap', width: '20em' },
        { field: 'NoOFscript', header: 'No. Of Scripts', width: '8em', format: true, align: true },
        { field: 'NetAsset', header: '% Net Asset', width: '8em', format: true, align: true }
    ]

    public PortfolioColumns = [
        { field: 'sHoldingName', header: 'Description', width: '20em', headerFilter: 'sHoldingName', tooltip: true },
        { field: 'sAssetName', header: 'Asset', width: '12em', headerFilter: 'sAssetName', tooltip: true },
        { field: 'sInstrumentName', header: 'Instruments', width: '12em', headerFilter: 'sInstrumentName', tooltip: true },
        { field: 'sIndustry', header: 'Industry', width: '12em', headerFilter: 'Industry', tooltip: true },
        { field: 'Rating', header: 'Rating', width: '10em', headerFilter: 'Rating' },
        { field: 'nNetAssetPercent', header: '%NetAsset', width: '8em', format: true, align: true },
        { field: 'nNoOfShares', header: 'No of Shares', width: '9em', arrowClass: true },
        { field: 'nPortfolioValue', header: 'Value(Crs.)', width: '8em', format4: true, align: true }, //4decimals
        { field: 'nPrevClose', header: 'Prev Close', width: '8em', format: true, align: true },
        { field: 'Change', header: '%Change', width: '8em', format: true, align: true },
        { field: 'n52WeeksHigh', header: '52WkHigh', width: '8em', format: true, align: true },
        { field: 'n52WeeksLow', header: '52WkLow', width: '8em', format: true, align: true },
        { field: 'nPERatio', header: 'PE Ratio', width: '8em', format: true, align: true }
    ]

    public WhatsInWhatsOutColumns = [
        { field: 'CompanyName', header: 'Company Name', width: '20em', headerFilter: 'SchemeName', tooltip: true },
        { field: 'Industry', header: 'Industry', width: '8em' },
        { field: 'HoldingPer', header: '%Holding', width: '8em', format: true, align: true },
        { field: 'InOut', header: 'In/Out', width: '6em' }
    ]

    public AgentswithoutBusinessColumns = [
        { field: 'sAgentName', header: 'Partner Name', width: '12em', headerFilter: 'sAgentName', tooltip: true },
        { field: 'sMobile', header: 'Mobile', width: '8em' },
        { field: 'sEmailId', header: 'EmailId', width: '17em' },
        { field: 'sRMName', header: 'RM Name', width: '12em', headerFilter: 'sRMName', tooltip: true }
    ]
    public ClientWithoutInvestmentColumns = [
        { field: 'sClientName', header: 'Client Name', width: '12em', headerFilter: 'sClientName', tooltip: true },
        { field: 'sAgentName', header: 'Partner Name', width: '12em', headerFilter: 'sAgentName', tooltip: true },
        { field: 'sRMName', header: 'RM Name', width: '12em', headerFilter: 'sRMName', tooltip: true },
    ]
    public FoliowithZeroBalanceColumns = [
        { field: 'sClientName', header: 'Client Name', width: '12em', headerFilter: 'sClientName', tooltip: true },
        { field: 'sAMCName', header: 'AMC Name', width: '12em', headerFilter: 'sAMCName', tooltip: true, NotTitleCase: true },
        { field: 'sFolio_No', header: 'Folio No', width: '8em' },
        { field: 'sAgentName', header: 'Partner Name', width: '12em' },
        { field: 'sRMName', header: 'RM Name', width: '12em', headerFilter: 'sRMName', tooltip: true }
    ]
    public RMwithoutBusinessColumns = [
        { field: 'sRMName', header: 'RM Name', width: '12em', headerFilter: 'sRMName', tooltip: true },
        { field: 'sBranchName', header: 'Branch Name', width: '12em', headerFilter: 'sBranchName', tooltip: true },
        { field: 'sMobile', header: 'Mobile', width: '8em' },
        { field: 'sEmailId', header: 'EmailId', width: '17em' },

    ]
    public ClientNeverInvestInSIPColumns = [
        { field: 'sClientName', header: 'Client Name', width: '12em', headerFilter: 'sClientName', tooltip: true },
        { field: 'sAgentName', header: 'Partner Name', width: '12em', headerFilter: 'sAgentName', tooltip: true },
        { field: 'sRMName', header: 'RM Name', width: '12em', headerFilter: 'sRMName', tooltip: true },
        { field: 'sPAN', header: 'PAN', width: '8em' },
        { field: 'totFund', header: 'Current Valuation', width: '12em', format: true, align: true }
    ]

    public FoliosWithoutPANColumns = [
        { field: 'sClientName', header: 'Client Name', width: '12em', headerFilter: 'sClientName', tooltip: true },
        { field: 'sAMCName', header: 'AMC Name', width: '12em', headerFilter: 'sAMCName', tooltip: true, NotTitleCase: true },
        { field: 'sFolio_No', header: 'Folio No', width: '8em' },
        { field: 'sAgentName', header: 'Partner Name', width: '12em', headerFilter: 'sAgentName', tooltip: true },
        { field: 'sRMName', header: 'RM Name', width: '12em', headerFilter: 'sRMName', tooltip: true }
    ]

    public BenchMarkColumns = [
        { field: 'sSchemeName', header: 'Scheme Name', width: '17em', headerFilter: 'sSchemeName', tooltip: true, NotTitleCase: true },
        { field: 'SchemeReturn', header: 'Scheme Return%', width: '8em', format: true, align: true },
        { field: 'BenchMark', header: 'Benchmark', width: '8em', headerFilter: 'Benchmark', tooltip: true, NotTitleCase: true, IsMarginLeft: true },
        { field: 'BenchMarkReturn', header: 'Benchmark Return%', width: '7em', format: true, align: true },
        { field: 'sSchemeCategoryName', header: 'Category Name', width: '14em', headerFilter: 'sSchemeCategoryName', tooltip: true, NotTitleCase: true, IsMarginLeft: true },
        //, format: true
    ]

    public BenchMarkIndexColumns = [

        { field: 'sSchemeName', header: 'Scheme Name', width: '8em', headerFilter: 'sSchemeName', tooltip: true, NotTitleCase: true },
        { field: 'SchemeReturn', header: 'Scheme Return%', width: '6em', format: true, align: true },
        { field: 'sSchemeCategoryName', header: 'Category Name', width: '10em', headerFilter: 'sSchemeCategoryName', tooltip: true, NotTitleCase: true, IsMarginLeft: true },
        //, format: true , align: true
    ]

    public DueSIPInnerColumn = [
        { field: 'sInv_Name', header: 'Client Name', width: '9em', headerFilter: 'sInv_Name', tooltip: true },
        { field: 'sScheme_Name', header: 'Scheme Name', width: '10em', headerFilter: 'sScheme_Name', tooltip: true, NotTitleCase: true },
        { field: 'sFolio_No', header: 'Folio No', width: '7em', align: true },
        { field: 'nAmount', header: 'SIP Amt', width: '7em', format: true, align: true, headerFilter: 'nAmount' },
        // { field: 'Message', header: 'Message', width: '6em', btnClass: 'pi pi-envelope bttn-edit', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Message' },
    ]


    public MISDataColumns = [
        { field: 'sFundName', header: 'Fund Name', width: '6em', headerFilter: 'sFundName', tooltip: true, NotTitleCase: true },
        { field: 'sSchemeLName', header: 'Scheme Name', width: '10em', headerFilter: 'sSchemeLName', tooltip: true, NotTitleCase: true },
        { field: 'nSchemeAUM', header: 'Scheme AUM (Cr.)', width: '6em', headerFilter: 'nSchemeAUM', format: true, align: true },
        { field: 'nNoOfShares', header: 'No Of Shares', width: '6em', align: true },
        { field: 'nPortfolioValue', header: 'Portfolio Value (Cr.)', width: '6em', headerFilter: 'nPortfolioValue', format: true, align: true },
        { field: 'nInvestmentPercentage', header: 'Investment Percentage', width: '6em', headerFilter: 'nInvestmentPercentage', format: true, align: true },
    ]

    public MISCombinationDataColumns = [
        { field: 'sFundName', header: 'Fund Name', width: '6em', headerFilter: 'sFundName', tooltip: true, NotTitleCase: true },
        { field: 'sSchemeLName', header: 'Scheme Name', width: '18em', headerFilter: 'sSchemeLName', tooltip: true, NotTitleCase: true },
        { field: 'totPer', header: 'Total Percentage', width: '6em', headerFilter: 'totPer', format: true, align: true },
        { field: 'Details', header: 'Details', width: '6em', btnClass: 'fa fa-bars bttn-edit', showButtonLabel: false, tooltip: true, Type: 'Button', FieldName: 'Details' },
    ]

    public MISDetailViewDataColumns = [

        { field: 'sSchemeLName', header: 'Scheme Name', width: '11em', headerFilter: 'sSchemeLName', tooltip: true, NotTitleCase: true },
        { field: 'sFTDescription', header: 'Scrip Name', width: '10em', headerFilter: 'sFTDescription', tooltip: true, NotTitleCase: true },
        { field: 'nSchemeAUM', header: 'Scheme AUM (Cr.)', width: '7em', headerFilter: 'nSchemeAUM', format: true, align: true },
        { field: 'nNoOfShares', header: 'No Of Shares', width: '7em', headerFilter: 'nNoOfShares', format: true, align: true },
        { field: 'nPortfolioValue', header: 'Portfolio Value (Cr.)', width: '7em', headerFilter: 'nPortfolioValue', format: true, align: true },
        { field: 'nInvestmentPercentage', header: 'Investment Percentage', width: '8em', headerFilter: 'nInvestmentPercentage', format: true, align: true }

    ]

    public MailBackServiceColumns = [
        { field: 'sClientName', header: 'Client Name', width: '15em', headerFilter: 'sClientName', tooltip: true },
        { field: 'nPAN', header: 'PAN No', width: '11em', headerFilter: 'nPAN' },
        { field: 'sEmailId', header: 'Email ID', width: '17em', headerFilter: 'sEmailId' },
    ]

    public MailConfigGrid = [
        { field: 'sSARN', header: 'ARN', tooltip: true },
        { field: 'sRTA', header: 'RTA' },
        { field: 'sEmailId', header: 'Email ID' },
        { field: 'sPassword', header: 'Password' }
    ]

    public ARNConfigGrid = [
        { field: 'sARN', header: 'ARN', tooltip: true },
        { field: 'sUserId', header: 'BSE User Id' },
        { field: 'sMemberCode', header: 'BSE Member Code' },
        { field: 'sPassword', header: 'Password' },
        { field: 'isDefault', header: 'Default' },
        { field: 'sEUIN', header: 'EUIN' }
    ]

    public FamilyWiseSIPDetails = [
        { field: 'sClientName', header: 'Client Name', width: '28em', tooltip: true, headerFilter: "sClientName", NotTitleCase: true, TotalColumn: 'sSchemeLName' },
        { field: 'sSchemeLName', header: 'Scheme Name', width: '28em', tooltip: true, headerFilter: "sSchemeLName", NotTitleCase: true, TotalColumn: 'sSchemeLName' },
        { field: 'sFolio_No', header: 'Folio No', width: '13em', TotalColumn: 'sSchemeLName' },
        { field: 'SIP Frequency', header: 'Frequency', width: '13em', TotalColumn: 'sSchemeLName' },
        { field: 'SIPAmount', header: 'Installment', width: '13em', format: true, headerFilter: "SIPAmount", customFilter: "exact", TotalColumn: 'sSchemeLName' },
        { field: 'Reg Date', header: 'Reg Date', width: '10em', date: true, TotalColumn: 'sSchemeLName' },
        { field: 'Start Date', header: 'Start Date', width: '10em', date: true, TotalColumn: 'sSchemeLName' },
        { field: 'End Date', header: 'End Date', width: '10em', date: true, TotalColumn: 'sSchemeLName' },
        { field: 'Deduction Date', header: 'Deduction Date', width: '12em', TotalColumn: 'sSchemeLName' }

    ];

    public MergedClientList = [
        { field: 'sClientName', header: 'Client Name', width: '28em', tooltip: true, headerFilter: "sClientName", NotTitleCase: true, TotalColumn: 'sSchemeLName' },
        { field: 'sPAN', header: 'Client PAN / Folio', width: '28em', tooltip: true, headerFilter: "sSchemeLName", NotTitleCase: true, TotalColumn: 'sSchemeLName' },
        { field: 'sMergedClientName', header: 'Merged Client Name', width: '13em', TotalColumn: 'sSchemeLName' },
        { field: 'nPAN', header: 'Merged Client PAN / Folio', width: '13em', TotalColumn: 'sSchemeLName' }
    ];

    public RTACredentials = [
        { field: 'sARN', header: 'ARN', tooltip: true },
        { field: 'sRTA', header: 'RTA' },
        { field: 'sUsername', header: 'User Id' },
        { field: 'sPassword', header: 'Password' }
    ]

    public CAMSSecurityQuestions = [
        { field: 'sARN', header: 'ARN', tooltip: true },
        { field: 'sQuestion', header: 'Question' },
        { field: 'sAnswer', header: 'Answer' }
    ]

}