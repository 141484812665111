import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { RegEx } from '../common/constants';

@Directive({
  selector: '[appFieldValidation]'
})
export class FieldValidationDirective {

  constructor(private el: ElementRef) { }

  @Input() appFieldValidation: any;
  @Output() check = new EventEmitter<string>();

  @HostListener("input", ["$event"]) onInputChange(event) {

    let pressValue = event.data;
    const initalValue = this.el.nativeElement.value;
    switch (this.appFieldValidation) {

      case "number":
        this.el.nativeElement.value = initalValue.replace(/[^0-9]/g, "");
        break;
      case "decimals":
        this.el.nativeElement.value = initalValue.replace(/[^0-9\.]/g, "");
        if (this.el.nativeElement.value.split(".").length > 2) {
          this.el.nativeElement.value = this.el.nativeElement.value.replace(/\.+$/, "");
        }
        break;
      case "alphaNumericSpecial":
        if (!RegEx.AlphaNumericSpecial.test(pressValue)) {
          this.el.nativeElement.value = this.el.nativeElement.value.slice(0, -1)
        }
        break;
      case "alphaNumericSpace":
        if (!RegEx.AlphaNumericSpace.test(pressValue)) {
          this.el.nativeElement.value = this.el.nativeElement.value.slice(0, -1)
        }
        break;
      case "alphaNumericWithoutSpace":
        this.el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9]*/g, "");
        break;
      case "alphabet":
        this.el.nativeElement.value = initalValue.replace(/[^A-Z a-z]*/g, "");
        break;
      case "email":
        // (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(initalValue))
        if (!RegEx.Email.test(initalValue)) {
          this.el.nativeElement.classList.add("error");
        } else {
          this.el.nativeElement.classList.remove("error");
        }
        break;
      case "pan":
        if (!RegEx.pan.test(initalValue)) {
          this.el.nativeElement.classList.add("error");
        } else {
          this.el.nativeElement.classList.remove("error");
        }
        break;
      default:
        let reg = new RegExp(this.appFieldValidation)
        if (!reg.test(initalValue)) {
          this.el.nativeElement.value = this.el.nativeElement.value.slice(0, -1);
  
        }
    }

    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
