import { PipeTransform, Injectable, Pipe } from '@angular/core';

@Pipe({
    name: 'INR4'
})
@Injectable()
export class CurrencyPipeComponentFixed4 implements PipeTransform {
    transform(input: any) {
        if (!isNaN(input)) {
            if (input === null) {
                input = 0;
            }
            let num = parseFloat(input).toFixed(4)          
            var result: any = num.toString().split('.');
            var d = Math.round(result[1] * 10 ^ 4) / 10 ^ 4;
            var lastThree = result[0].substring(result[0].length - 3);
            var otherNumbers = result[0].substring(0, result[0].length - 3);
            if (otherNumbers != '')
                lastThree = ',' + lastThree;
            var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

            if (result.length > 1) {
                output += "." + result[1];
            }          
            return output;
        }
    }
}
